import React, {Component} from 'react'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import { Button, Select, TextField } from '@cimpress/react-components'
import countryCodes from '../commonData/countryCodes.json'
import { dispatchClosePromptForCustomAddress, dispatchAddress } from '../../redux/shipping/actions'
import { clear } from '../../redux/alert/actions'

export class AddressForm extends Component {
  constructor (props) {
    super(props)
    this.state = { ...props, dirty: false, requirePostalCode: true }
    autobind(this)
  }

  changeHandler (field) {
    return ({ target: { value } }) => {
      if (this.state[field] === value) return
      this.setState({ ...this.state, address: { ...this.state.address, [field]: value }, dirty: true })
    }
  }

  valid() {
    if (this.validAttentionTo() && this.state.address.street && this.state.address.locality
      && this.validPostalCode() &&  this.state.address.countryCode) {
        return true
      }
    return false
  }

  validPostalCode() {
    if (this.state.requirePostalCode && !this.state.address.postalCode) {
      return false
    }

    return true
  }

  validAttentionTo() {
    if (this.state.address.name || this.state.address.company) {
      return true
    }

    return false
  }

  render () {
    const { translate } = this.props
    return (
      <div>
        <TextField
          id='shipping-address-name'
          name='name'
          value={this.state.address.name}
          required= {!this.state.address.company}
          type='text'
          label={translate('createLabel.address.placeholder.NAME')}
          onChange={this.changeHandler('name')} />
        <TextField
          id='shipping-address-company'
          name='company'
          value={this.state.address.company}
          required={!this.state.address.name}
          label={translate('createLabel.address.placeholder.COMPANY')}
          type='text'
          onChange={this.changeHandler('company')} />
        <TextField
          id='shipping-address-street'
          name='street'
          value={this.state.address.street}
          label={translate('createLabel.address.placeholder.ADDRESS_1')}
          required
          type='text'
          onChange={this.changeHandler('street')} />
        <TextField
          id='shipping-address-street2'
          name='street2'
          value={this.state.address.street2}
          label={translate('createLabel.address.placeholder.ADDRESS_2')}
          type='text'
          onChange={this.changeHandler('street2')} />
        <div className='row'>
          <div className='col-sm-7'>
            <TextField
              id='shipping-address-locality'
              name='locality'
              value={this.state.address.locality}
              label={translate('createLabel.address.placeholder.CITY')}
              required
              type='text'
              onChange={this.changeHandler('locality')} />
          </div>
          <div className='col-sm-5'>
            <TextField
              id='shipping-address-postalCode'
              name='postalCode'
              value={this.state.address.postalCode}
              label={translate('createLabel.address.placeholder.POSTAL_CODE')}
              required={this.state.requirePostalCode}
              type='text'
              onChange={this.changeHandler('postalCode')} />
          </div>
        </div>
        <TextField
          id='shipping-address-region'
          name='region'
          value={this.state.address.region}
          label={translate('createLabel.address.placeholder.REGION')}
          type='text'
          onChange={this.changeHandler('region')} />
        <Select
          id='shipping-address-countryCode'
          label={translate('createLabel.address.placeholder.COUNTRY')}
          name='countryCode'
          required
          value={countryCodes.find(countrycode => countrycode.value === this.state.address.countryCode)}
          className='drop-up'
          onChange={({ value }) => {
            this.setState({ requirePostalCode: !!countryCodes.find(country => country.value === value).requirePostalCode })
            this.changeHandler('countryCode')({ target: { value } })
          }}
          options={countryCodes} />
        <TextField
          id='shipping-address-phone'
          name='phoneNumber'
          label={translate('createLabel.address.placeholder.PHONE')}
          type='text'
          value={this.state.address.phone}
          onChange={this.changeHandler('phone')}/>
        <div className='flex justify-end'>
          <Button
            type='primary'
            id='ship'
            disabled={!this.valid()}
            onClick={async () => {
              if (this.state.dirty) {
                clear({slot: 'ship'})
                await dispatchAddress(this.state.address, this.props.shipmentId)
              }
              dispatchClosePromptForCustomAddress()
            }}>
            {translate('ship.SUBMIT')}
          </Button>
          &nbsp;&nbsp;
          <Button type='default' id='clear' onClick={dispatchClosePromptForCustomAddress}>
            {translate('ship.CANCEL')}
          </Button>
        </div>
      </div>
    )
  }
}

export default withLocalize(connect((state, ownProps) => ({
  address: ownProps.address,
  shipmentId: ownProps.shipmentId
}))(AddressForm))
