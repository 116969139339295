import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import PropTypes from 'prop-types'
import { withLocalize, getTranslate } from 'react-localize-redux'
import { alert } from '../../redux/alert/actions'
import { formatErrorMessage } from '../../lib/common/errorFormattingService'
import * as languageService from '../../lib/language/languageService'

import * as returnAddressActions from '../../redux/returnAddresses/actions'

import * as authenticationService from '../../lib/authentication/authenticationService'
import * as analyticsService from '../../lib/analytics/analyticsService'
import App from './App'
import '../../assets/css/style.css'
import { getUserSettings } from '../../lib/customizr/customizrService'

export class AppRouter extends Component {
  constructor (props){
    super(props)
    autobind(this)
  }

  async componentDidMount () {
    await this.autologin()

    if(authenticationService.isLoggedIn()) {
      let userProfile = authenticationService.getProfile()
      analyticsService.run(userProfile)
    }

    await this.getReturnAddresses()
  }

  async getReturnAddresses() {
    returnAddressActions.dispatchReturnAddressLoadingStarted()

    try {
      const userSettings = await getUserSettings()

      if (userSettings?.returnAddresses?.length > 0) {
        returnAddressActions.dispatchUpdateReturnAddress(userSettings.returnAddresses)
      }
    } catch (error) {
      alert({ message: languageService.format('alert.RETURN_ADDRESS_SERVICE_FAILURE') + `\nError: ${formatErrorMessage(error)}`, level: 'danger', slot: 'returnAddress' })
    } finally {
      returnAddressActions.dispatchReturnAddressLoadingComplete()
    }
  }

  render () {
    return <App {...this.props.passedProps}/>
  }

  async autologin() {
    await authenticationService.logIn()
  }
}

AppRouter.propTypes = {
  passedProps: PropTypes.object
}

export default withLocalize(
  connect(
    (state, ownProps) => {
      return {
        translate: getTranslate(state.localize),
        passedProps: ownProps
      }
    }
  )(AppRouter))
