import React, { Component } from 'react'
import { getTranslate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import { Modal } from '@cimpress/react-components'
import AddressForm from '../../../components/addressForm/addressForm'
import CarrierServiceComponent from '../../../components/carrierServiceSelection'
import ShipBox from '../../../components/shipBox'
import * as shippingActions from '../../../redux/shipping/actions'
import { alert, clear } from '../../../redux/alert/actions'
import { shipViaSapiAndShipman } from '../../../lib/shipping/shippingService'
import { sendCreateLabelConfirmation } from '../../../lib/email/emailService'
import * as labelService from '../../../lib/shipping/labelService'
import { flatten } from 'lodash'

export class ShipmentListView extends Component {
  constructor () {
    super()
    autobind(this)
  }

  async onShip() {
    const { translate } = this.props
    shippingActions.dispatchStartShipping()
    let labelShipmentPairs
    try {
      let shipments = await shipViaSapiAndShipman()
      let errorResult = shipments.find(shipment => !!shipment.error)
      if (errorResult) {
        alert({ message: errorResult.error, level: 'danger', slot: 'ship' })
      } else {
        const labels = flatten(shipments.map(shipment => {return shipment.labels.map(label => {return {label: label.label, shipment: shipment.shipment}})}))
        labelShipmentPairs = await labelService.getPdfLabels(labels)
        clear()
      }
    } catch (err) {
      alert({ message: err, level: 'danger', slot: 'ship' })
    }

    if (this.props.sendEmailNotification) {
      try {
        await Promise.all(labelShipmentPairs.map(lblShipment => {
          return sendCreateLabelConfirmation(lblShipment.shipment.trackingInfo, lblShipment.pdfLabels, lblShipment.shipment)
        }))
        alert({ level: 'success', message: translate('ship.controller.SHIPMENTS_SUCCESSFUL', {email: this.props.email}), slot: 'ship' })
      } catch (err) {
        alert({ level: 'success', message: translate('ship.controller.SHIPMENTS_SUCCESSFUL_FAIL_EMAIL', {supportEmail: getSupportEmailAddress()}), slot: 'ship' })
      }
    } else {
      alert({ level: 'success', message: translate('ship.controller.SHIPMENTS_SUCCESSFUL_NO_EMAIL'), slot: 'ship' })
    }

    shippingActions.dispatchEndShipping()
    shippingActions.dispatchClearShipping()
  }

  render () {
    const { translate, shipments } = this.props
    const buttonText = translate('ship.SHIP_LABEL') + (shipments.length > 1 ?
      translate('ship.MULTI_PACKAGE_NO_SHIP', { numPackages: shipments.length, packageType: 'boxes' }) :
      translate('ship.ONE_PACKAGE_NO_SHIP', { packageType: 'box' }))
    return (
      <div className='flex justify-center' >
        <div style={{width:'55%'}}>
          <p>&nbsp;</p>
          <h2>
            <i className='fa fa-cubes'></i>&nbsp;
            {translate('uploadShipments.SHIPMENT_LIST')}
          </h2>
          {shipments.length > 0 && shipments.map((shipment, index) => {
            const address = shipment.shippingAddress
            return (
              <div className='bg-blank flex column' id='package-group'>
                <Modal
                  bsStyle='warning'
                  show={this.props.promptForCustomAddress || false}
                  title={translate('createLabel.ENTER_SHIPPING_ADDRESS')}
                >
                  <AddressForm address={address} shipmentId={shipment.shipmentId} />
                </Modal>
                <div className={'flex'} style={{marginTop: '5px'}}>
                  <div className='address-box' style={{marginTop: '10px'}}>
                    <div>
                      <label id='shipping-info'>
                        {translate('uploadShipments.SHIPMENT_NUMBER', { index: index + 1 })}
                      </label>
                    </div>
                    <div>
                      <ul className='addressCardList'>
                        {<li key={index} id={'shipping-address'}>{`${address.name}, ${address.street}`}</li>}
                      </ul>
                    </div>
                  </div>
                  <div className='form-group' id='box-info' style={{marginLeft: '15px'}}>
                    <div className='flex column'>
                      <CarrierServiceComponent
                        id={`carrier-service-${index}`}
                        shipment={shipment}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <ShipBox
          numberOfShipments={this.props.shipments.length}
          createShipment={this.onShip}
          disableShipButton={this.props.shippingInProgress || !this.props.shipments.every(({ selectedCarrierServiceKey }) => selectedCarrierServiceKey)}
        />
      </div>
    )
  }
}

export default withLocalize(connect(
  (state) => {
    return {
      translate: getTranslate(state.localize),
      shipments: state.shipping.shipments,
      promptForCustomAddress: state.shipping.promptForCustomAddress,
      shippingInProgress: state.shipping.shippingInProgress,
      email: state.auth.profile.email,
      sendEmailNotification: state.settings && state.settings.customShip && state.settings.customShip.sendEmailNotification,
    }
  }
)(ShipmentListView))
