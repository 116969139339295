import * as authenticationProxy from '../authentication/authenticationProxy'
import store from '../../redux'

export function getShippingOptions(shippingAddress, returnAddress, weightInLbs, localShipDateTime) {
  const { logisticsLocation: { currentLocation: { localeSettings: { weightUnits } } } } = store.getState()

  var request = {
    method: 'POST',
    url: '/api/v1/shippingOptions',
    data: {
      destinationAddress: shippingAddress,
      shipFromAddress: returnAddress,
      weight: `${weightInLbs} ${weightUnits}`,
      localShipDateTime
    }
  }

  return authenticationProxy.makeAuthenticatedShippingOptionsRequest(request)
}
