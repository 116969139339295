import React, {Component} from 'react'
import { getTranslate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import { Button, Select, TextField } from '@cimpress/react-components'
import { addOrUpdateReturnAddresses } from '../../../lib/customizr/customizrService'
import * as returnAddressActions from '../../../redux/returnAddresses/actions'
import { alert } from '../../../redux/alert/actions'
import states from '../../../components/addressForm/state.json'
import createLoqateControl from '../../../lib/common/loqate'

const defaultReturnAddress = {
  name: '',
  company: '',
  street: '',
  street2: '',
  postalCode: '',
  locality: '',
  region: '',
  countryCode: 'US'
}

export class ReturnAddressUpdateForm extends Component {
  constructor (props) {
    super(props)

    const currentReturnAddress = this.props.returnAddresses ? this.props.returnAddresses[0].address : defaultReturnAddress
    this.state = {
      returnAddress: currentReturnAddress,
      defaultCountry: {
        label: 'United States of America',
        value: 'US'
      }
    }

    autobind(this)
  }

  setUpLoqate() {
    let loqateControl = createLoqateControl([this.state.defaultCountry.value])

    loqateControl.listen('populate', (address) => {
      this.onStreetChange({ target: { value: address.Line1 } })
      this.onStreet2Change({ target: { value: address.Line2 } })
      this.onRegionChange({ target: { value: address.Province } })
      this.onLocalityChange({ target: { value: address.City } })
      this.onPostalCodeChange({ target: { value: address.PostalCode } })
    })
  }

  async componentDidMount() {
    this.setUpLoqate()
  }
  async saveReturnAddress (e) {
    e.preventDefault()

    const response = await addOrUpdateReturnAddresses([{
      returnAddressId: 1,
      default: true,
      address: this.state.returnAddress
    }])
    if (response) {
      returnAddressActions.dispatchUpdateReturnAddress(response.returnAddresses)
      alert({
        message: this.props.translate('updateReturnAddress.controller.RETURN_ADDRESS_UPDATED_SUCCESSFUL'),
        level: 'success',
        slot: 'updateReturnAddress'
      })
    }

    this.props.closeUpdateWindow()
  }

  isAddressValid () {
    if (!this.state.returnAddress.name || !this.state.returnAddress.street || !this.state.returnAddress.postalCode ||
      !this.state.returnAddress.locality || !this.state.returnAddress.region) {
      return false
    }
    return true
  }

  onNameChange (e) {
    this.setState(prevState => ({
      ...prevState,
      returnAddress: {
        ...prevState.returnAddress,
        name: e.target.value
      }
    }))
  }

  onCompanyChange (e) {
    this.setState(prevState => ({
      ...prevState,
      returnAddress: {
        ...prevState.returnAddress,
        company: e.target.value
      }
    }))
  }

  onStreetChange (e) {
    const newStreetValue = e.target.value
    this.setState(prevState => ({
      ...prevState,
      returnAddress: {
        ...prevState.returnAddress,
        street: newStreetValue
      }
    }))
  }

  onStreet2Change (e) {
    this.setState(prevState => ({
      ...prevState,
      returnAddress: {
        ...prevState.returnAddress,
        street2: e.target.value
      }
    }))
  }

  onPostalCodeChange (e) {
    this.setState(prevState => ({
      ...prevState,
      returnAddress: {
        ...prevState.returnAddress,
        postalCode: e.target.value
      }
    }))
  }

  onLocalityChange (e) {
    this.setState(prevState => ({
      ...prevState,
      returnAddress: {
        ...prevState.returnAddress,
        locality: e.target.value
      }
    }))
  }

  onRegionChange (e) {
    this.setState(prevState => ({
      ...prevState,
      returnAddress: {
        ...prevState.returnAddress,
        region: e.target.value
      }
    }))
  }

  stateCodesList () {
    return states[this.state.defaultCountry.value]
  }

  render () {
    const { translate } = this.props
    return (
      <div>
        <TextField
          id='shipping-address-name'
          name='name'
          value={this.state.returnAddress.name}
          required
          type='text'
          label={translate('createLabel.address.placeholder.NAME')}
          onChange={this.onNameChange} />
        <TextField
          id='shipping-address-company'
          name='company'
          value={this.state.returnAddress.company}
          label={translate('createLabel.address.placeholder.COMPANY')}
          type='text'
          onChange={this.onCompanyChange} />
        <TextField
          id='shipping-address-street'
          name='street'
          value={this.state.returnAddress.street}
          label={translate('createLabel.address.placeholder.ADDRESS_1')}
          required
          type='text'
          onChange={this.onStreetChange} />
        <TextField
          id='shipping-address-street2'
          name='street2'
          value={this.state.returnAddress.street2}
          label={translate('createLabel.address.placeholder.ADDRESS_2')}
          type='text'
          onChange={this.onStreet2Change} />
        <div className='row'>
          <div className='col-sm-7'>
            <TextField
              id='shipping-address-locality'
              name='locality'
              value={this.state.returnAddress.locality}
              label={translate('createLabel.address.placeholder.CITY')}
              required
              type='text'
              onChange={this.onLocalityChange} />
          </div>
          <div className='col-sm-5'>
            <TextField
              id='shipping-address-postalCode'
              name='postalCode'
              value={this.state.returnAddress.postalCode}
              label={translate('createLabel.address.placeholder.POSTAL_CODE')}
              required
              type='text'
              onChange={this.onPostalCodeChange} />
          </div>
        </div>
        <Select
          containerclassname='shipping-address-region-select'
          id='shipping-address-region'
          label={translate('createLabel.address.placeholder.REGION')}
          name='region'
          value={this.stateCodesList().find(state => state.value === this.state.returnAddress.region)}
          required
          onChange={({ value }) => this.onRegionChange({ target: { value } })}
          options={this.stateCodesList()}
        />
        <TextField
          id='shipping-address-countryCode'
          name='countryCode'
          value={this.state.defaultCountry.label}
          label={translate('createLabel.address.placeholder.COUNTRY')}
          disabled
          type='text'
        />
        <div className='flex justify-end'>
          <Button
            type='primary'
            id='ship'
            disabled={!this.isAddressValid()}
            onClick={(e) => {
              this.saveReturnAddress(e)
            }}>
            {translate('updateReturnAddress.UPDATE')}
          </Button>
          &nbsp;&nbsp;
          <Button type='default' id='clear' onClick={this.props.closeUpdateWindow}>
            {translate('updateReturnAddress.CANCEL')}
          </Button>
        </div>
      </div>
    )
  }
}

export default withLocalize(connect(
  (state) => {
    return {
      translate: getTranslate(state.localize),
      returnAddresses: state.returnAddress.returnAddresses
    }
  }
)(ReturnAddressUpdateForm))
