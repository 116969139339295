import * as authenticationProxy from '../authentication/authenticationProxy'
import * as languageService from '../language/languageService'
import { getSupportEmailAddress } from '../email/addresses'
import { formatErrorMessage } from '../common/errorFormattingService'

export function cancelShipment (sapiShipmentId) {
  let cancellationRequest = {
    method: 'PATCH',
    uri: `/v3/shipments/${sapiShipmentId}`,
    data: { status: 'cancelled' }
  }

  try {
    return authenticationProxy.makeAuthenticatedSapiRequest(cancellationRequest)
  } catch(err) {
    throw new Error(languageService.format('createLabel.ERROR_CANCEL_SAPI_SHIPMENT', ['supportEmail'], [getSupportEmailAddress()]) + ' ' + formatErrorMessage(err))
  }
}
