import React, {Component} from 'react'
import { withLocalize, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import { Alert, Button, Radio, RadioGroup } from '@cimpress/react-components'

import CarrierServiceSelection from '../../components/carrierServiceSelection'
import ShippingAddress from './view/shippingAddress'
import ShippingOptions from './view/shippingOptions'
import states from '../../components/addressForm/state.json'
import * as shippingActions from '../../redux/shipping/actions'
import * as shippingService from '../../lib/shipping/shippingService'
import { sendCreateLabelConfirmation } from '../../lib/email/emailService'
import { getSupportEmailAddress } from '../../lib/email/addresses'
import * as labelService from '../../lib/shipping/labelService'
import { flatten } from 'lodash'

export class CreateLabel extends Component {
  constructor(props) {
    super(props)
    this.state = {items: [{}], alertDismissed: true, alertMessage: '', alertStatus: 'success' }
    autobind(this)
  }

  regionRequired(countryCode) {
    return !!states[countryCode]
  }

  valid () {
    if ( !this.props.shipping.shipments[0].shippingAddress.name ||
      !this.props.shipping.shipments[0].shippingAddress.street ||
      (this.props.shipping.shipments[0].requirePostalCode && !this.props.shipping.shipments[0].shippingAddress.postalCode) || !this.props.shipping.shipments[0].shippingAddress.locality
      || !this.props.shipping.shipments[0].shippingAddress.countryCode || (this.regionRequired(this.props.shipping.shipments[0].shippingAddress.countryCode) && !this.props.shipping.shipments[0].shippingAddress.region) ||
      !this.props.shipping.shipments[0].weight ||
      !this.props.shipping.shipments[0].selectedCarrierServiceKey || this.props.shipping.shipments[0].carrierRecommendationInProgress
      || this.props.shipping.shippingInProgress || this.validWeight() === false) {
        return false
      }
    return true
  }

  validWeight() {
    let totalWeight = 0
    for(const item of this.state.items) {
      if (!!['htsCode', 'declaredValue'].find(prop => item[prop]) && !item.weight) return false
      totalWeight += Number(item.weight || 0)
    }
    if (Number(this.props.shipping.shipments[0].weight) < totalWeight) return
    return true
  }

  onPayableByChange(e, value) {
    shippingActions.dispatchUpdatePayableBy(value)
  }

  async onButtonPress(event) {
    event.preventDefault()

    if(event.detail === 0) {
      return
    }

    const { translate, sendEmailNotification } = this.props

    if (!this.validWeight()) {
      this.setState({...this.state, alertDismissed: false, alertStatus: 'danger', alertMessage: translate('ship.controller.ERROR_WEIGHT')})
      return
    }

    shippingActions.dispatchStartShipping()

    let trackingInfo, shipments, pdfLabels
    try {
      shipments = await shippingService.shipViaSapiAndShipman()
      if (shipments[0].error) {
        this.setState({...this.state, alertDismissed: false, alertStatus: 'danger', alertMessage: translate('ship.controller.ERROR', {message: shipments[0].error})})
        shippingActions.dispatchEndShipping()
        return
      } else {
        const labels = flatten(shipments.map(shipment => {return shipment.labels.map(label => {return {label: label.label, shipment: shipment.shipment}})}))
        pdfLabels = await labelService.getPdfLabels(labels)
        trackingInfo = shipments[0].shipment.trackingInfo
      }
    } catch (err) {
      this.setState({...this.state, alertDismissed: false, alertStatus: 'danger', alertMessage: translate('ship.controller.ERROR', {message: err})})
      shippingActions.dispatchEndShipping()
      return
    }

    if (sendEmailNotification) {
      try {
        await sendCreateLabelConfirmation(trackingInfo, pdfLabels, shipments[0].shipment)
        this.setState({...this.state, alertDismissed: false, alertStatus: 'success', alertMessage: translate('ship.controller.SHIPMENT_SUCCESSFUL_PDF', {email: this.props.email})})
      } catch (err) {
        this.setState({...this.state, alertDismissed: false, alertStatus: 'success', alertMessage: translate('ship.controller.SHIPMENT_SUCCESSFUL_FAIL_EMAIL_PDF', {supportEmail: getSupportEmailAddress()})})
      }
    } else {
      this.setState({...this.state, alertDismissed: false, alertStatus: 'success', alertMessage: translate('ship.controller.SHIPMENT_SUCCESSFUL_NO_EMAIL_PDF')})
    }

    this.clearAlert({alertStatus: 'danger'})
    this.dispatchClearLabel()
    shippingActions.dispatchEndShipping()
  }

  clearAlert({alertStatus}) {
    if (this.state.alertStatus === alertStatus) {
      this.setState({...this.state, alertDismissed: true, alertStatus: 'success', alertMessage: ''})
    }
  }

  dispatchClearLabel () {
    this.setState({...this.state, items: [{}]})
    shippingActions.dispatchClearShipping()
  }

  onClearAll(e) {
    this.setState({items: [{}], alertDismissed: true, alertMessage: '', alertStatus: 'success' })
    this.dispatchClearLabel()
    e.preventDefault()
  }

  render() {
    const { translate } = this.props

    return (
      <div>
        <Alert
          status={this.state.alertStatus}
          message={this.state.alertMessage}
          dismissible={true}
          dismissed={this.state.alertDismissed}
        />
        <div className='flex justify-center'>
          <form role='form' name='createLabel'>
            <legend style={{ 'borderBottom': 'none' }}>{translate('createLabel.TITLE')}</legend>
            <fieldset>
              <div className='bg-blank form-group custom-shipment-form'>
                <div className='form-group bg-toned form-column'>
                  <ShippingAddress />
                </div>
                <div className='form-group form-column'>
                  <div
                    className='flex space-between column'
                    style={{ height: '100%' }}
                  >
                    <div className='flex column'>
                      <ShippingOptions />
                      {this.props.showPayableByRadio &&
                        <div className='flex column'>
                          <label className='section-description'> {translate('createLabel.BILL_SHIPMENT')} </label>
                          <RadioGroup name='billingType' onChange={this.onPayableByChange} defaultSelected='personal' inline>
                            <Radio label={translate('createLabel.PERSONAL')} value='personal' />
                            <Radio label={translate('createLabel.BUSINESS')} value='business' />
                          </RadioGroup>
                        </div>}
                      <br />
                      <CarrierServiceSelection
                        shipment={this.props.shipping.shipments[0]}
                      />
                    </div>
                    <div className='flex justify-end'>
                      <Button
                        type='primary'
                        name='custom-ship-PDF'
                        disabled={!this.valid()}
                        id='PDF'
                        onClick={(e) => {
                          return this.onButtonPress(e)
                        }}
                      >
                        {translate('createLabel.DOWNLOAD_LABEL')}
                      </Button>
                      &nbsp;&nbsp;
                      <Button type='default' id='clear' onClick={(e) => {
                        return this.onClearAll(e)}}
                      >
                        {translate('createLabel.CLEAR')}
                      </Button>
                      {this.props.shipping.shippingInProgress && <i className='fa fa-spinner fa-spin' style={{ fontSize: '28px' }} />}
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <div style={{ height: '130px' }}></div>
          </form>
        </div>
      </div>
    )
  }
}

export default withLocalize(connect(
  (state) => {
    return {
      translate: getTranslate(state.localize),
      shipping: state.shipping,
      email: state.auth.profile.email,
      currentLocation: state.logisticsLocation.currentLocation,
      showPayableByRadio: state.settings && state.settings.customShip && state.settings.customShip.showPayableByOptions,
      settings: state.settings,
      sendEmailNotification: state.settings && state.settings.customShip && state.settings.customShip.sendEmailNotification,
      weightUnits: state.logisticsLocation.currentLocation && state.logisticsLocation.currentLocation.localeSettings.weightUnits,
      lengthUnits: state.logisticsLocation.currentLocation && state.logisticsLocation.currentLocation.localeSettings.lengthUnits
  }
  })(CreateLabel))
