import React, { Component } from 'react'
import { getTranslate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import { ReactTablev6 } from '@cimpress/react-components'
import * as shipmanProxy from '../../lib/shipman/shipmanProxy'
import moment from 'moment'

const columns = [
  { Header: 'Ship Date', accessor: 'shipDate' },
  { Header: 'Shipment ID', accessor: 'shipmentId' },
  { Header: 'Recipient', accessor: 'recipient' },
  { Header: 'Destination', accessor: 'destination' },
  { Header: 'Tracking ID', accessor: 'trackingId' },
  { Header: 'Status', accessor: 'status' }
]

export class MyShipments extends Component {
  constructor(props) {
    super(props)
    this.state = {shipments: [], loading: true}
    autobind(this)
  }

  async componentDidMount () {
    const shipments = await this.getShipments()
    this.setState({ shipments: shipments.map((shipment, index) => {
      const address = shipment.destinationAddress
      return {
        shipDate: shipment.shipmentCreationDateTime && moment(shipment.shipmentCreationDateTime).local().format('YYYY-MM-DD HH:mm'),
        shipmentId: shipment.shipmentId,
        recipient: address.name,
        destination: `${address.street1}, ${address.locality}, ${address.region} ${address.postalCode}`,
        trackingId: <a rel="noopener noreferrer" target="_blank" id={index}>{shipment.packages[0].trackingId}</a>,
        status: shipment.packages[0].trackingStatus || shipment.status.toUpperCase()
      }
    }), loading: false })

    shipments.forEach((shipment, index) => {
      document.getElementById(index).setAttribute("href", shipment.packages[0].trackingUrl)
    })
  }

  getCreatorUserIds () {
    const userIds = []
    const wasClaim = 'https://claims.cimpress.io/was'
    const { userProfile } = this.props
    if (userProfile.sub) userIds.push(userProfile.sub)
    if (userProfile[wasClaim]?.length) userIds.push(...userProfile[wasClaim])
    return userIds
  }

  async getShipments () {
    const shipments = []
    const { fulfillmentLocationId } = this.props
    const creatorUserIds = this.getCreatorUserIds()
    await Promise.all(creatorUserIds.map(async (creator) => {
      const shipmentSearchResult = await shipmanProxy.search({
        creator,
        fulfillmentLocationId
      })
      if (shipmentSearchResult?.shipments?.length) shipments.push(...shipmentSearchResult.shipments)
    }))
    return shipments
  }

  render () {
    const { translate } = this.props
    return (
      <div className='row'>
        <p>&nbsp;</p>
        <h2>
          <i className='fa fa-cubes'></i>&nbsp;
          {translate('headings.MY_SHIPMENTS')}
        </h2>
        <br />
        <br />
        <ReactTablev6
          columns={columns}
          data={this.state.shipments}
          condensed={false}
          selectable={false}
          filterable={false}
          loading={this.state.loading}
          loadingText={translate('menuBar.LOADING')}
          noDataText={translate('myShipments.NO_SHIPMENT')}
        />
      </div>
    )
  }
}

export default withLocalize(connect(
  (state) => {
    return {
      translate: getTranslate(state.localize),
      userProfile: state.auth.profile,
      fulfillmentLocationId: state.fulfillmentLocation.currentLocation?.id
    }
  })(MyShipments))
