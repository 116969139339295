import { setup } from 'axios-cache-adapter'

const api = setup({
  cache: {
    maxAge: 1000 * 60 * 15, // 15 minutes
    exclude: {
      paths: [
        '\/v0\/fulfillment-locations\/.*\/items\/' // exclude item retrieval to support address change requests
      ]
    }
  }
})

export default api