import { unit } from 'mathjs'
import { v4 } from 'uuid'

import store from '../../../redux'

export function formatSapiv3ShipRequest(shipment) {
  const {
    logisticsLocation: {currentLocation: {localeSettings: {weightUnits}}},
    fulfillmentLocation: {currentLocation: {id: locationKey}},
    returnAddress: customerReturnAddress
  } = store.getState()

  const shippingAddress = Object.assign({}, shipment.shippingAddress)
  if (shippingAddress.company) {
    shippingAddress.contact = shippingAddress.name
    shippingAddress.name = shippingAddress.company
  }
  delete shippingAddress.company

  const returnAddress = Object.assign({}, customerReturnAddress.returnAddresses[0].address)
  if (returnAddress.company) {
    returnAddress.contact = returnAddress.name
    returnAddress.name = returnAddress.company
  }
  delete returnAddress.company

  const packages = [{weight: shipment.weight}]
  const carrierServiceKey = shipment.selectedCarrierServiceKey

  const carrierService = shipment.shippingOptions && shipment.shippingOptions.find(option => option.service.carrierServiceKey === carrierServiceKey)
  const labelTemplateUSASize =
    carrierService && carrierService.labelTemplates && carrierService.labelTemplates.find(template => template.mediaType.includes('pdf') && template.size === '8.5x11')

  const labelPreferences = labelTemplateUSASize ? { labelTemplateKey: labelTemplateUSASize.key } : { mediaType: 'application/pdf' }

  return {
    locationKey,
    carrierServiceKey,
    returnAddress,
    packages: formatPackages(packages, weightUnits),
    shippingAddress,
    pickupPreferences: { firstAvailablePickup: true },
    references: [v4(), 'Created by Shipping Console'],
    labelPreferences
  }
}

function formatPackages (packages, weightUnits) {
  return packages.map(({ weight }) => ({
      reference: v4(),
      weight: { value: unit(weight, weightUnits).toNumber('kg'), unit: 'kg' }
    })
  )
}
