import * as capiProxy from './capiProxy'
import * as languageService from '../language/languageService'
import { alert } from '../../redux/alert/actions'

export async function getCarrierServicesFromShippingOptions(shippingOptions = []) {
  try {
    return await Promise.all(shippingOptions.map(async shippingOption => {
      const { name: carrierServiceName, labelTemplates } = await capiProxy.getCarrierService(shippingOption.service.carrierServiceKey)
      return { ...shippingOption, labelTemplates, carrierServiceName }
    }))
  } catch (error) {
    alert({ message: languageService.format('ship.ERROR_CAPI') + error, level: 'danger', slot: 'carrier' })
  }
}

export async function getCarrierService(carrierServiceKey) {
  try {
    let carrierService = await capiProxy.getCarrierService(carrierServiceKey)
    return carrierService
  } catch (error) {
    alert({ message: languageService.format('ship.ERROR_CAPI') + error, level: 'danger', slot: 'carrier' })
  }
}
