import React, { Component } from 'react'
import { Alert } from '@cimpress/react-components'
import { connect } from 'react-redux'
import ContactWrapper from './ContactWrapper'
import PackingGuidance from './PackingGuidance'

import { clear } from '../../redux/alert/actions'

export class Alerts extends Component {
  render () {
    const alerts = this.props.alerts.map(({ level, slot, message, key, dismissible }) => (
      <Alert
        message={
          slot === 'packingGuidance' // magic word
            ? <PackingGuidance guidance={message} />
            : <ContactWrapper message={message} type={level} />
        }
        dismissible={dismissible}
        onDismiss={() => clear({ key })}
        type={level}
        key={key}
      />
    ))

    return (
      <div style={{ whiteSpace: 'pre-wrap' }}>
        <div>{alerts}</div>
      </div>
    )
  }
}

export default connect(
  state => {
    return {
      alerts: state.alert.alerts
    }
  }
)(Alerts)
