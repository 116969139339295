'use strict'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'

export class PackingGuidance extends Component {
  render () {
    let guidanceBlock = this.props.guidance.messages.map(message => (
            <div>
             <li>{message}</li>
            </div>
          ))
    return (
      <div>
        <div><h5><Translate id='ship.PACKING_GUIDANCE_HEADER'/>{` ${this.props.guidance.itemId}: `}</h5></div>
        <div>
          {guidanceBlock}
        </div>
      </div>
    )
  }
}

export default connect(
  state => {
    return {
      alerts: state.alert.alerts
    }
  }
)(PackingGuidance)
