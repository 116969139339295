import { combineReducers } from 'redux'
import { localizeReducer } from 'react-localize-redux'

import alert from './alert/reducers'
import auth from './authentication/reducers'
import fulfillmentLocation from './fulfillmentLocation/reducers'
import shipping from './shipping/reducers'
import logisticsLocation from './logisticsLocation/reducers'
import common from './common/reducers'
import settings from './settings/reducers'
import returnAddress from './returnAddresses/reducers'
// Import all reducers and combine them here
export default combineReducers({
  alert,
  auth,
  fulfillmentLocation,
  shipping,
  logisticsLocation,
  localize: localizeReducer,
  common,
  settings,
  returnAddress
})
