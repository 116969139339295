import * as emailProxy from './emailProxy'
import store from '../../redux/index'
import config from '../../app-config.json'
import moment from 'moment'
import { pick } from 'lodash'

export function sendCreateLabelConfirmation (trackingInfo, pdfLabels, shipment) {
  let { auth: { profile } } = store.getState()

  let selectedShippingOption = shipment.shippingOptions
    .find(shippingOption => shippingOption.service.carrierServiceKey === shipment.selectedCarrierServiceKey)

  let shippingAddress = pick(shipment.shippingAddress,
    ['name', 'company', 'region', 'postalCode', 'countryCode'])
  shippingAddress.addressLine1 = shipment.shippingAddress.street
  shippingAddress.addressLine2 = shipment.shippingAddress.street2,
  shippingAddress.city = shipment.shippingAddress.locality

  let data = {
    shippingAddress,
    sender: {
      email: profile.email,
      firstName: profile.given_name,
      lastName: profile.family_name
    },
    shippingOption: {
      carrierService: selectedShippingOption.carrierServiceName,
      deliveryDate: moment(selectedShippingOption.estimatedArrivalDate.deliveryDate).format('dddd, MMMM Do, YYYY'),
      totalCost: selectedShippingOption.rates.discountedPrice,
      currencyCode: selectedShippingOption.rates.currency
    },
    trackingInfo: {
      trackingId: trackingInfo.trackingId,
      carrierTrackingUrl: trackingInfo.trackingUrl,
      trackTrackingUrl: `https://track.cimpress.io/?trackingNumbers=${trackingInfo.trackingId}`
    },
    pdfLabel: pdfLabels,
    shipmentId: shipment.id
  }

  return emailProxy.sendEmail(config.CREATE_LABEL_TEMPLATE_ID, data)
}
