/*global Blob*/

import FileSaver from 'file-saver'
import JSZip from 'jszip'
import 'blob.js'
import { arrayBufferToBase64 } from '../common/commonFormatterService'
import { makeAuthenticatedRequest } from '../authentication/authenticationProxy'


export async function getPdfLabels (labels) {
  if (labels.length === 1) {
    const {label, shipment} = labels[0]
    const shipmentName = shipment.shippingAddress.name.replace(/\s+/g, '')
    let pdfLabel = new Blob([label.content], {type: 'application/pdf'})
    FileSaver.saveAs(pdfLabel, `${shipmentName}.pdf`)
    return arrayBufferToBase64(label.content)
  } else if (labels.length > 1) {
    let labelShipmentPair = []
    const newZip = new JSZip()
    await Promise.all(labels.map(async ({label, shipment}, index) => {
      const shipmentName = shipment.shippingAddress.name.replace(/\s+/g, '')
      let pdfLabel = new Blob([label.content], {type: 'application/pdf'})
      await newZip.file(`${shipmentName}-${index}.pdf`, pdfLabel, {binary: true})
      let base64Label = [arrayBufferToBase64(label.content)]
      labelShipmentPair.push({pdfLabels: base64Label, shipment})
    }))
    const zipBlob = await newZip.generateAsync({type:"blob"})
    await FileSaver.saveAs(zipBlob, `shipments.zip`)
    return labelShipmentPair
  }
}

export async function retrieveLabelContent(shipment) {
  return Promise.all(shipment.labels.map(async label => {
    if (!label.content) {
      const { links } = label
      const { href: url, method } = links.find(({rel}) => rel === 'self')
      const content = Buffer.from(await makeAuthenticatedRequest({url, method, responseType: 'arraybuffer'}))
      label.content = content
    }
    return {label, shipment}
  }))
}
