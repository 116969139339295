export default (state = {}, action) => {
  switch (action.type) {
    case 'RETURN_ADDRESS_LOADING_STARTED':
      return {
        ...state,
        loadingInProgress: true
      }
    case 'RETURN_ADDRESS_LOADING_COMPLETE':
      return {
        ...state,
        loadingInProgress: false
      }
    case 'CLEAR_CREATE_RETURN_ADDRESS':
      // Clear countryCode when more countries added/no longer only US
      return {
        ...state,
        returnAddresses: undefined
      }
    case 'UPDATE_RETURN_ADDRESS':
      return {
        ...state,
        returnAddresses: action.returnAddresses
      }
    default: return state
  }
}
