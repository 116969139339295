// Import all initial state
import alert from './alert/state'
import auth from './authentication/state'
import fulfillmentLocation from './fulfillmentLocation/state'
import shipping from './shipping/state'
import logisticsLocation from './logisticsLocation/state'
import common from './common/state'
import settings from './settings/state'
import returnAddress from './returnAddresses/state'

// Merge all state into an initial state object and export it
export default {
  alert,
  auth,
  fulfillmentLocation,
  shipping,
  logisticsLocation,
  common,
  settings,
  returnAddress
}
