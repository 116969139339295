import React, { Component } from 'react'
import { withLocalize, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import { Button, Modal, ReactTablev6 } from '@cimpress/react-components'

import * as returnAddressActions from '../../../redux/returnAddresses/actions'
import { deleteReturnAddresses } from '../../../lib/customizr/customizrService'
import ReturnAddressUpdateForm from './returnAddressUpdateForm'
import { alert } from '../../../redux/alert/actions'

const columns = [
  { Header: 'Name', accessor: 'name' },
  { Header: 'Company', accessor: 'company' },
  { Header: 'Street', accessor: 'street' },
  { Header: 'Street2', accessor: 'street2' },
  { Header: 'PostalCode', accessor: 'postalCode' },
  { Header: 'Locality', accessor: 'locality' },
  { Header: 'Region', accessor: 'region' },
  { Header: 'CountryCode', accessor: 'countryCode' },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: data => {
      return (
        <>
          <i className='fa fa-pencil' onClick={data.value.popUpdateWindow} />
          &nbsp;
          &nbsp;
          {
            data.value.showDeleteIcon &&
            <i className='fa fa-trash-o' />
          }
        </>
      )
    }
  }
]

export class ReturnAddressTable extends Component {
  constructor (props) {
    super(props)
    this.state = { showUpdateWindow: false }
    autobind(this)
  }

  popUpdateWindow () {
    this.setState(
      {
        ...this.state,
        showUpdateWindow: true
      }
    )
  }

  closeUpdateWindow () {
    this.setState(
      {
        ...this.state,
        showUpdateWindow: false
      }
    )
  }

  async deleteReturnAddress (e) {
    e.preventDefault()

    await deleteReturnAddresses(this.props.returnAddresses)
    returnAddressActions.dispatchClearCreateReturnAddress()
  }

  render () {
    const { translate } = this.props

    return (
      <div className='row'>
        <p>&nbsp;</p>
        <h2>
          <i className='fa fa-map-marker' /> &nbsp;
          {translate('headings.MY_RETURN_ADDRESSES')}
        </h2>
        <br />
        <br />
        <Modal
          bsStyle='warning'
          show={this.state.showUpdateWindow}
          onRequestHide={this.closeUpdateWindow}
          closeOnOutsideClick
          closeButton
          title={translate('updateReturnAddress.TITLE_MESSAGE')}
        >
          <ReturnAddressUpdateForm closeUpdateWindow={this.closeUpdateWindow} />
        </Modal>
        <ReactTablev6
          columns={columns}
          data={this.props.returnAddresses.map((returnAddress) => {
            return {
              ...returnAddress.address,
              action:
              {
                popUpdateWindow: this.popUpdateWindow,
                alertFailureMessage: this.props.translate('updateReturnAddress.controller.DEFAULT_RETURN_ADDRESS_DELETED_FAILED'),
                showDeleteIcon: !!this.props.returnAddresses && this.props.returnAddresses.length > 1
              }
            }
          })}
          condensed={false}
          selectable={false}
          filterable={false}
          sortable={false}
          multiSort={false}
          loadingText={translate('menuBar.LOADING')}
          noDataText={translate('myReturnAddresses.NO_RETURN_ADDRESS')}
        />
        <div className='flex justify-end'>
          <Button
            type='default' id='delete' onClick={this.deleteReturnAddress}
          >
            Delete
          </Button>
        </div>
      </div>
    )
  }
}

export default withLocalize(
  connect(
    (state) => {
      return {
        translate: getTranslate(state.localize),
        returnAddresses: state.returnAddress.returnAddresses
      }
    })(ReturnAddressTable))
