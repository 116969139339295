import React, {Component} from 'react'
import { withLocalize, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import Select from 'react-select'
import moment from 'moment'
import { debounce } from 'lodash'
import { shapes } from '@cimpress/react-components'
const { Spinner } = shapes

import * as shippingOptionsService from '../../lib/shippingOptions/shippingOptionsService'
import * as capiService from '../../lib/capi/capiService'
import * as shippingActions from '../../redux/shipping/actions'
import countryCodes from '../commonData/countryCodes.json'

export class CarrierServiceSelection extends Component {
  constructor(props) {
    super(props)
    autobind(this)
    this.debouncedRetrieveShippingOptions = debounce(this.retrieveShippingOptions, 1000)
  }

  componentDidMount() {
    this.selectDefaultKeyIfUsingSapiServices()
    this.retrieveShippingOptions()
  }

  componentDidUpdate(prevProps) {
    if(this.shouldUpdateShippingOptions(prevProps)) {
      this.debouncedRetrieveShippingOptions()
    }

    this.selectDefaultKeyIfUsingSapiServices()
  }

  shouldUpdateShippingOptions(prevProps) {
    return !(
      prevProps.weight === this.props.weight &&
      prevProps.shippingAddress.postalCode === this.props.shippingAddress.postalCode &&
      prevProps.shippingAddress.countryCode === this.props.shippingAddress.countryCode
    ) && this.props.ccmEnabled
  }

  selectDefaultKeyIfUsingSapiServices() {
    const {ccmEnabled, sapiCarrierServices} = this.props
    if (ccmEnabled === false) {
      if (!this.props.selectedCarrierServiceKey && sapiCarrierServices && sapiCarrierServices.length > 0) {
        shippingActions.dispatchSelectedCarrierServiceKey(sapiCarrierServices[0].value, this.props.shipmentId)
      }
    }
  }

  getNonCcmShippingOptions() {
    const { translate } = this.props
    let helpText
    let options = this.props.sapiCarrierServices || []
    if (options.length < 1) {
      helpText = translate('createLabel.helpText.NO_CARRIER_SERVICES_CONFIGURED')
    }

    return {options, helpText}
  }

  getCcmEnabledOptions() {
    const { translate, settings: { customShip: { displayNonContractPricing } } } = this.props
    let options = []
    let helpText
    if( this.props.shippingOptions && this.props.shippingOptions.length > 0 ) {
      options = this.props.shippingOptions.map((shippingOption) => {
        let label

        if( this.props.displayPrices ) {
          label =
            <div>
              <div>
                <span stle={{'float': 'left'}}>{shippingOption.carrierServiceName}</span>
                <span style={{'fontWeight': 'bold', 'float': 'right', 'paddingLeft':'10px'}}>{this.formatDeliveryCost(shippingOption.rates.discountedPrice, shippingOption.rates.currency)}</span>
                {displayNonContractPricing && <span style={{'color': 'red', 'textDecoration': 'line-through', 'float': 'right'}}>{this.formatDeliveryCost(shippingOption.rates.listPrice, shippingOption.rates.currency)}</span>}
              </div>
              <div>
                <span className='delivery-date-span'>Delivery on {this.formatDeliveryDate(shippingOption.estimatedArrivalDate.deliveryDate)}</span>
              </div>
            </div>
        } else {
          label =
            <div>
              <div>
                <span stle={{'float': 'left'}}>{shippingOption.carrierServiceName}</span>
              </div>
              <div>
                <span className='delivery-date-span'>Delivery on {this.formatDeliveryDate(shippingOption.estimatedArrivalDate.deliveryDate)}</span>
              </div>
            </div>
        }


        return (
          {
            value: shippingOption.service.carrierServiceKey,
            label: label,
            style: {paddingTop: '12px'}
          }
        )
      })
    } else {
      helpText = translate('createLabel.shippingOptions.ENTER_OPTIONS')
    }

    return {options, helpText}
  }

  formatDeliveryCost(cost, currencyCode) {
    if(currencyCode === 'USD') {
      return `$${Number(cost).toFixed(2)}`
    } else {
      return `${Number(cost).toFixed(2)} ${currencyCode}`
    }
  }

  formatDeliveryDate(dateString) {
    return moment(dateString).format('dddd, MMMM Do, YYYY')
  }

  onChange (e) {
    shippingActions.dispatchSelectedCarrierServiceKey(e.value, this.props.shipmentId)
  }

  hasRequiredFields() {
    let requiredFields = ['currentLocation', 'shippingAddress.countryCode', 'shippingAddress.name', 'shippingAddress.locality', 'shippingAddress.street', 'weight']

    if(countryCodes.find(countryCode => { return countryCode.value === this.props.shippingAddress.countryCode })?.requirePostalCode) {
      requiredFields.push('shippingAddress.postalCode')
    }

    return requiredFields.every(field => {
      return !!field.split('.').reduce((value, fieldPart) => value[fieldPart], this.props)
    })
  }

  async retrieveShippingOptions() {
    shippingActions.startShippingOptionsRetrieval(this.props.shipmentId)
    let shippingOptions = []
    if( this.hasRequiredFields() ) {
      shippingOptions = await shippingOptionsService.getShippingOptions(
        this.props.shippingAddress,
        { postalCode: this.props.returnAddress.postalCode, countryCode: this.props.returnAddress.countryCode },
        this.props.weight)
      shippingOptions = await capiService.getCarrierServicesFromShippingOptions(shippingOptions)
      if (!this.props.displayPrices) {
        this.sortShippingOptions(shippingOptions)
      }
    }

    shippingActions.finishShippingOptionsRetrieval(shippingOptions, this.props.shipmentId)
  }

  sortShippingOptions(shippingOptions) {
    shippingOptions.sort( (a, b) => {
      let timeDiff = moment(a.estimatedArrivalDate.deliveryDate).diff(b.estimatedArrivalDate.deliveryDate)
      if( timeDiff === 0 ) {
        return a.carrierServiceName.localeCompare(b.carrierServiceName)
      }

      return timeDiff
    })
  }

  body() {
    let shippingOptions, options = [], helpText
    const { translate, carrierRecommendationInProgress } = this.props
    if (this.props.ccmEnabled === undefined) {
      helpText = translate('createLabel.helpText.CCM_RETRIEVAL_FAILURE')
    } else if (this.props.ccmEnabled) {
      shippingOptions = this.getCcmEnabledOptions()
      options = shippingOptions.options
      helpText = shippingOptions.helpText
    } else if (this.props.ccmEnabled === false){
      shippingOptions = this.getNonCcmShippingOptions()
      options = shippingOptions.options
      helpText = shippingOptions.helpText
    }

    if (!carrierRecommendationInProgress) {
      return (
        <div>
          <Select
            value={options.find(option => option.value === this.props.selectedCarrierServiceKey)}
            options={options}
            placeholder={!options.length ? 'No recommendations found' : 'Carrier Service'}
            onChange={this.onChange}
            helpText={helpText}
            status={!options.length ? 'error' : undefined}
            disabled={options.length < 1}
          />
        </div>
      )
    }
    else {
      return (
        <div style={{width:'37%', margin:'auto'}}>
          <Spinner size='medium' />
        </div>
      )
    }
  }

  render () {
    let title = (this.props.displayPrices && this.props.ccmEnabled) ? 'createLabel.CARRIER_SELECTION_PRICES' : 'createLabel.CARRIER_SELECTION_NO_PRICES'
    return (
      <div className='flex column'>
        <label className='section-description'>{this.props.translate(title)}</label>
        {
          this.body()
        }
      </div>
    )
  }
}

export default withLocalize(connect(
  (state, ownProps) => {
    return {
      translate: getTranslate(state.localize),
      fulfillmentLocation: state.fulfillmentLocation?.currentLocation?.id,
      ccmEnabled: state.fulfillmentLocation?.ccmEnabled,
      sapiCarrierServices: state.fulfillmentLocation?.sapiCarrierServices,
      weight: ownProps.shipment?.weight,
      shippingAddress: Object.assign({}, ownProps.shipment?.shippingAddress),
      shippingOptions: ownProps.shipment?.shippingOptions,
      selectedCarrierServiceKey: ownProps.shipment?.selectedCarrierServiceKey,
      weightUnits: state.logisticsLocation.currentLocation?.localeSettings.weightUnits,
      currencyCode: state.logisticsLocation.currentLocation?.localeSettings.currencyCode,
      displayPrices: state.settings?.customShip?.displayPrices,
      currentLocation: state.logisticsLocation.currentLocation,
      settings: state.settings,
      shipmentId: ownProps.shipment?.shipmentId,
      carrierRecommendationInProgress: ownProps.shipment.carrierRecommendationInProgress,
      returnAddress: state.returnAddress?.returnAddresses?.[0].address
    }
  }
)(CarrierServiceSelection))
