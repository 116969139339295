import React, { Component } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CreateLabel from '../createLabel/Container'
import UploadShipments from '../uploadShipments/Container'
import MyShipments from '../myShipments/Container'
import ReturnAddress from '../createReturnAddress/Container'
import Contact from '../contact/View'

import { dispatchFinishInitialLoad } from '../../redux/common/actions'

import Header from './view/header'
import Alerts from '../alert/Alerts'

import '../../assets/css/style.css'
import * as authenticationService from '../../lib/authentication/authenticationService'

import { renderToStaticMarkup } from 'react-dom/server'
import store from '../../redux/index'
import { withLocalize, setActiveLanguage, getTranslate } from 'react-localize-redux'

import * as browserLanguage from '../../lib/language/browserLanguage'
import MyReturnAddresses from '../myReturnAddresses/Container'
const languages = ['en', 'fr', 'it', 'de', 'nl', 'zh', 'es']

export class App extends Component {
  constructor(props) {
    super(props)
    this.configLocalization(props)
  }

  configLocalization (props) {
    props.initialize({languages, translations: {}, options: {renderToStaticMarkup, renderInnerHtml: true}})

    languages.map((language) => {
      const translation = require(`../../lib/language/translations/language-${language}.json`)
      props.addTranslationForLanguage(translation, language)
    })
  }

  async componentDidMount () {
    let browserLang = browserLanguage.getBrowserLanguage()

    let userData = await authenticationService.getUserMetadata()

    if (userData && userData.language && languages.includes(userData.language)) {
      store.dispatch(setActiveLanguage(userData.language))
    } else if (browserLang && languages.includes(browserLang)) {
      store.dispatch(setActiveLanguage(browserLang))
    }

    dispatchFinishInitialLoad()
  }

  render () {
    return (
      <div style={{paddingBottom:'110px'}}>
        <Location>
          {location => {
            return <Header {...this.props} location={location} />
          }}
        </Location>
        <div className='container'>
          <Alerts />
          <div>
            <Routes>
              <Route path='/contact' element={<Contact/>} key='contact' />
              { this.props.isAuthenticated && <Route path='/return-address' element={<ReturnAddress/>} key='returnAddress' /> }
              { (this.props.isAuthenticated && this.props.returnAddressExists) && [<Route path='/custom-shipment' element={<CreateLabel/>} key='createLabel' />,
                <Route path='/my-shipments' element={<MyShipments/>} key='myShipments' />,
                <Route path='/my-return-addresses' element={<MyReturnAddresses/>} key='myReturnAddresses' />,
                <Route path='/' element={<UploadShipments/>} key='createShipments' />] }
              { (this.props.isAuthenticated && !this.props.returnAddressExists) && <Route path='/' element={<ReturnAddress/>} key='returnAddress' />}
            </Routes>
          </div>
        </div>
      </div>
    )
  }
}

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
}

export default
  connect(
    state => {
      return {
        isAuthenticated: state.auth.isAuthenticated,
        returnAddressExists: !!state.returnAddress.returnAddresses,
        translate: getTranslate(state.localize),
        auth: state.auth
      }
    }
  )(withLocalize(App))

function Location({children}) {
  let location = useLocation()
  return children(location)
}
