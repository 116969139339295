import React, { Component } from 'react'
import autobind from 'react-autobind'
import { getTranslate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Button, Modal, shapes } from '@cimpress/react-components'
import * as shippingActions from '../../../redux/shipping/actions'
import { useDropzone } from 'react-dropzone'
import { alert } from '../../../redux/alert/actions'
import * as spreadsheet from '../../../lib/spreadsheet/spreadsheet'
import CreateLabel from '../../createLabel/Container'
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle'
import { teal } from '@cimpress/react-components/lib/colors'
import IconUploadBottom from '@cimpress-technology/react-streamline-icons/lib/IconUploadBottom'
import IconCommonFileText from '@cimpress-technology/react-streamline-icons/lib/IconCommonFileText'

const { Robot } = shapes
function FileDropzone({ props }) {
  const onDrop = (acceptedFiles) => {
    shippingActions.dispatchFile(acceptedFiles[0])
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.xlsx, .csv'
  })

  const beforeUpload = () => {
    return (
      <div>
        <h3 style={{marginTop: '100px', marginLeft: '15px', fontWeight: 'bold', 'fontFamily': 'Avenir'}}>
          <IconUploadBottom size='2x' style={{paddingTop: '5px'}}/>&nbsp;
          {props.translate('uploadShipments.DRAG_LIST')}
        </h3>
        <h4 style={{marginBottom: '100px', marginLeft: '35px', fontWeight: 'normal', 'fontFamily': 'Avenir'}}>
          {props.translate('uploadShipments.BROWSE')}
        </h4>
      </div>
    )
  }

  const afterUpload = () => {
    return (
      <div>
        <h3 style={{marginTop: '100px', marginLeft: '15px', fontWeight: 'bold', 'fontFamily': 'Avenir'}}>
          <IconCommonFileText size='2x' style={{paddingTop: '5px'}}/> &nbsp;
          {props.file.name}
        </h3>
        <h4 style={{marginBottom: '100px', marginLeft: '35px', fontWeight: 'normal', 'fontFamily': 'Avenir'}}>
          {props.translate('uploadShipments.UPLOAD_ANOTHER')}
        </h4>
      </div>
    )
  }

  return (
    <div
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {!!props.file ? afterUpload() : beforeUpload()}
    </div>
  )
}

export class UploadView extends Component {
  constructor(props) {
    super(props)
    this.state = {promptForManualShipment: false}
    autobind(this)
  }

  onClick() {
    try {
      spreadsheet.handleUpload(this.props.file)
    } catch (err) {
      alert({message: err, level: 'danger', slot: 'upload'})
    }
  }

  enterShipment() {
    this.setState({promptForManualShipment: true})
  }

  closeModal() {
    shippingActions.dispatchClearShipping()
    this.setState({promptForManualShipment: false})
  }

  render () {
    const { translate } = this.props
    return (
      <div>
        {this.props.isAuthenticated && <div style={{ margin: '0 auto', width: '60%' }}>
          <Modal
            bsStyle='warning'
            show={this.state.promptForManualShipment || false}
            onRequestHide={this.closeModal}
            closeOnOutsideClick={true}
            closeButton={true}
          >
            <CreateLabel />
          </Modal>
          <div className='flex space-between bg-blank' >
            <div className='flex column bg-toned' style={{ width: '50%' }}>
              <FileDropzone props={this.props} />
              <Button type='default' id='upload' onClick={this.onClick} disabled={!this.props.file} style={{margin: '15px'}}>
                {translate('uploadShipments.UPLOAD')}
              </Button>
            </div>
            <div className='flex column' style={{ width: '50%'}}>
              <p>&nbsp;</p>
              <div className='flex'>
                <h3 onClick={this.enterShipment} id='enter-shipment' style={{cursor: 'pointer', marginTop: '85px', marginLeft: '30px', marginBottom: '40px', fontWeight: 'bold', 'fontFamily': 'Avenir'}}>
                  <u>{translate('uploadShipments.ENTER_SHIPMENT')}</u>
                </h3>
              </div>
            </div>
          </div>
        </div>}
        <h3 style={{marginTop: '70px', fontWeight: 'bold', 'textAlign': 'center' }}>
          <IconInformationCircle color={teal.base} size='2x' style={{paddingTop: '5px'}}/>&nbsp;&nbsp;
          {translate('uploadShipments.NEED_INFORMATION')}
        </h3>
        <h4 style={{fontWeight: 'normal', marginLeft: '20px', 'textAlign': 'center' }}>
          <a href='/assets/excel_template.xlsx' download={'excel_template.xlsx'}>
            <u>{translate('uploadShipments.DOWNLOAD_TEMPLATE')}</u>
          </a>
        </h4>
        <div className='flex justify-center' style={{marginTop: '10px'}}><Robot bsStyle='success' alternate/></div>
      </div>
    )
  }
}

export default withLocalize(connect(
  (state) => {
    return {
      translate: getTranslate(state.localize),
      isAuthenticated: state.auth.isAuthenticated,
      file: state.shipping.file
    }
  }
)(UploadView))
