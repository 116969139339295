import store from '../index'

export const dispatchReturnAddressLoadingStarted = () => {
  store.dispatch({ type: 'RETURN_ADDRESS_LOADING_STARTED' })
}

export const dispatchReturnAddressLoadingComplete = () => {
  store.dispatch({ type: 'RETURN_ADDRESS_LOADING_COMPLETE' })
}

export const dispatchClearCreateReturnAddress = () => {
  store.dispatch({ type: 'CLEAR_CREATE_RETURN_ADDRESS' })
}

export const dispatchUpdateReturnAddress = (returnAddresses) => {
  store.dispatch({ type: 'UPDATE_RETURN_ADDRESS', returnAddresses })
}
