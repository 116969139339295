import React, { Component } from 'react'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import ShipmentListView from './view/shipmentListView'
import UploadView from './view/uploadView'

export class UploadShipments extends Component {

  render () {
    const { shipments } = this.props
    return (
      <div>
        {(shipments.length > 0 && shipments[0].shipmentId !== 1) ? <ShipmentListView /> : <UploadView />}
      </div>
    )
  }
}

export default withLocalize(connect(
  (state) => {
    return {
      shipments: state.shipping.shipments
    }
  }
)(UploadShipments))
