import React, { Component } from 'react'
import { withLocalize, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import { Button, Select, TextField } from '@cimpress/react-components'
import * as returnAddressActions from '../../../redux/returnAddresses/actions'
import { alert } from '../../../redux/alert/actions'
import { updateUserSettings } from '../../../lib/customizr/customizrService'
import states from '../../../components/addressForm/state.json'
import createLoqateControl from '../../../lib/common/loqate'

const defaultReturnAddress = {
  name: '',
  company: '',
  street: '',
  street2: '',
  postalCode: '',
  locality: '',
  region: '',
  countryCode: 'US'
}

export class ReturnAddressForm extends Component {
  constructor (props) {
    super(props)

    const currentReturnAddress = this.props.returnAddresses ? this.props.returnAddresses[0].address : defaultReturnAddress

    this.state = {
      returnAddress: currentReturnAddress,
      defaultCountry: {
        label: 'United States of America',
        value: 'US'
      }
    }
    autobind(this)
  }

  setUpLoqate() {
    let loqateControl = createLoqateControl()

    loqateControl.listen('populate', (address) => {
    this.onStreetChange({ target: { value: address.Line1 } })
    this.onStreet2Change({ target: { value: address.Line2 } })
    this.onRegionChange({ target: { value: address.Province } })
    this.onLocalityChange({ target: { value: address.City } })
    this.onPostalCodeChange({ target: { value: address.PostalCode } })
    })
  }

  async componentDidMount() {
    this.setUpLoqate()
  }

  async saveReturnAddress (e) {
    e.preventDefault()
    const response = await updateUserSettings({
      returnAddresses: [{
        returnAddressId: 1,
        default: true,
        address: this.state.returnAddress
      }]
    })
    if (response) {
      returnAddressActions.dispatchUpdateReturnAddress(response.returnAddresses)
      alert({
        message: this.props.translate('createReturnAddress.controller.RETURN_ADDRESS_CREATED_SUCCESSFUL'),
        level: 'success',
        slot: 'createReturnAddress'
      })
    }
  }

  isAddressValid () {
    if (!this.state.returnAddress.name || !this.state.returnAddress.street || !this.state.returnAddress.postalCode ||
      !this.state.returnAddress.locality || !this.state.returnAddress.region) {
      return false
    }
    return true
  }

  onClearAll (e) {
    this.setState({
      ...this.state,
      returnAddress: defaultReturnAddress
    })

    e.preventDefault()
  }

  onNameChange (e) {
    this.setState(prevState => ({
      ...prevState,
      returnAddress: {
        ...prevState.returnAddress,
        name: e.target.value
      }
    }))
  }

  onCompanyChange (e) {
    this.setState(prevState => ({
      ...prevState,
      returnAddress: {
        ...prevState.returnAddress,
        company: e.target.value
      }
    }))
  }

  onStreetChange (e) {
    const newStreetValue = e.target.value
    this.setState(prevState => ({
      ...prevState,
      returnAddress: {
        ...prevState.returnAddress,
        street: newStreetValue
      }
    }))
  }

  onStreet2Change (e) {
    this.setState(prevState => ({
      ...prevState,
      returnAddress: {
        ...prevState.returnAddress,
        street2: e.target.value
      }
    }))
  }

  onPostalCodeChange (e) {
    this.setState(prevState => ({
      ...prevState,
      returnAddress: {
        ...prevState.returnAddress,
        postalCode: e.target.value
      }
    }))
  }

  onLocalityChange (e) {
    this.setState(prevState => ({
      ...prevState,
      returnAddress: {
        ...prevState.returnAddress,
        locality: e.target.value
      }
    }))
  }

  onRegionChange (e) {
    this.setState(prevState => ({
      ...prevState,
      returnAddress: {
        ...prevState.returnAddress,
        region: e.target.value
      }
    }))
  }

  stateCodesList () {
    return states[this.state.defaultCountry.value]
  }

  returnAddressForm () {
    const { translate } = this.props
    return (
      <div>
        <label className='section-description'>
          {translate('createReturnAddress.ENTER_RETURN_ADDRESS')}
        </label>
        <TextField
          id='shipping-address-name'
          name='name'
          value={this.state.returnAddress.name}
          required
          type='text'
          autoComplete='off'
          label={translate('createLabel.address.placeholder.NAME')}
          onChange={this.onNameChange}
        />
        <TextField
          id='shipping-address-company'
          name='company'
          value={this.state.returnAddress.company}
          autoComplete='off'
          label={translate('createLabel.address.placeholder.COMPANY')}
          type='text'
          onChange={this.onCompanyChange}
        />
        <TextField
          id='shipping-address-street'
          data-address-role='streetNameAndHouseNumber'
          name='street'
          value={this.state.returnAddress.street}
          label={translate('createLabel.address.placeholder.ADDRESS_1')}
          required
          type='text'
          autoComplete='off'
          style={{ zIndex: '10' }}
          onChange={this.onStreetChange}
        />
        <TextField
          id='shipping-address-street2'
          name='street2'
          value={this.state.returnAddress.street2}
          autoComplete='off'
          label={translate('createLabel.address.placeholder.ADDRESS_2')}
          type='text'
          style={{ marginTop: '4%' }}
          onChange={this.onStreet2Change}
        />
        <div className='row'>
          <div className='col-sm-6'>
            <TextField
              id='shipping-address-locality'
              data-address-role='city'
              name='locality'
              value={this.state.returnAddress.locality}
              autoComplete='off'
              label={translate('createLabel.address.placeholder.CITY')}
              required
              type='text'
              onChange={this.onLocalityChange}
            />
          </div>
          <div className='col-sm-6'>
            <TextField
              id='shipping-address-postalCode'
              data-address-role='postalCode'
              name='postalCode'
              value={this.state.returnAddress.postalCode}
              autoComplete='off'
              label={translate('createLabel.address.placeholder.POSTAL_CODE')}
              required={false}
              type='text'
              onChange={this.onPostalCodeChange}
            />
          </div>
        </div>
        <Select
          containerclassname='shipping-address-region-select'
          id='shipping-address-region'
          label={translate('createLabel.address.placeholder.REGION')}
          name='region'
          value={this.stateCodesList().find(state => state.value === this.state.returnAddress.region)}
          required
          onChange={({ value }) => this.onRegionChange({ target: { value } })}
          options={this.stateCodesList()}
        />
        <TextField
          id='shipping-address-countryCode'
          name='countryCode'
          value={this.state.defaultCountry.label}
          label={translate('createLabel.address.placeholder.COUNTRY')}
          disabled
          type='text'
        />
      </div>
    )
  }

  render () {
    const { translate } = this.props

    return (
      <div className='flex justify-center'>
        <form role='form' name='createReturnAddress'>
          <legend style={{ borderBottom: 'none' }} className='text-center'>
            {translate('createReturnAddress.TITLE_MESSAGE')}
          </legend>
          <fieldset>
            <div className='bg-blank form-group create-return-address-form'>
              <div className='form-group bg-toned form-column'>
                {this.returnAddressForm()}
                <div className='flex justify-end'>
                  <Button
                    id='save'
                    className='pull-right mp-2'
                    disabled={!this.isAddressValid()}
                    onClick={(e) => {
                      this.saveReturnAddress(e)
                    }}
                  >
                    {translate('createReturnAddress.SAVE')}
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className='pull-right mp-2'
                    type='default'
                    id='clear'
                    onClick={(e) => {
                      return this.onClearAll(e)
                    }}
                  >
                    {translate('createReturnAddress.CLEAR')}
                  </Button>
                </div>
              </div>
            </div>
          </fieldset>
          <div style={{ height: '130px' }} />
        </form>
      </div>
    )
  }
}

export default withLocalize(connect(
  (state) => {
    return {
      translate: getTranslate(state.localize),
      returnAddressExists: !!state.returnAddress.returnAddresses,
      returnAddresses: state.returnAddress.returnAddresses
    }
  }
)(ReturnAddressForm))
