'use strict'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate, withLocalize } from 'react-localize-redux'

const attachContactForTypes = ['danger', 'warning']

import { getSupportEmailAddress } from '../../lib/email/addresses'

export class ContactWrapper extends Component {
  render () {
    if (attachContactForTypes.find(type => type === this.props.type)) {
      return (
        <div>
          <div>
            {this.props.message}
          </div>
          <div>
            <br/><Translate id='contact.CONTACT_EMAIL' data={{ supportEmail: getSupportEmailAddress() }}/>
          </div>
        </div>
      )
    }

    return this.props.message
  }
}

export default withLocalize(connect(
  (state) => {
    return {
      currentLocation: state.logisticsLocation.currentLocation || {}
    }
  })(ContactWrapper))
