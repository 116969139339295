import React, {Component} from 'react'
import { withLocalize, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import * as shippingActions from '../../../redux/shipping/actions'
import { TextField } from '@cimpress/react-components'

export class ShippingOptions extends Component {
  constructor(props) {
    super(props)
    autobind(this)
  }

  onWeightChange (e) {
    shippingActions.dispatchUpdateWeight(Number(e.target.value), this.props.shipmentId)
  }

  onReferenceChange (e) {
    shippingActions.dispatchUpdateLabelReference(e.target.value, this.props.shipmentId)
  }

  dimensionChangeHandler (dimension) {
    return ({ target: { value } }) => {
      shippingActions.dispatchUpdateDimensions({[dimension]: value}, this.props.shipmentId)
    }
  }

  render () {
    const { translate, lengthUnits: lengthUnit, width, height, depth, customShip } = this.props
    return (
      <div >
        <label className='section-description'>{translate('createLabel.SHIPPING_OPTIONS')}</label>
        <TextField
          id='box-weight'
          name='Box Weight'
          label={translate('createLabel.shippingOptions.WEIGHT', {units: this.props.weightUnits})}
          value={parseFloat(Number(this.props.weight).toFixed(2)) || undefined}
          required={!this.props.weight}
          type='number'
          onChange={this.onWeightChange}
          format='2'
          step='0.01'
          min='0'
          max='1000000'/>
        {customShip?.requestDimensions && <div className='flex space-between'>
          <div>
            <TextField
              style={{marginRight: '10%'}}
              name='width'
              label={translate('ship.boxes.WIDTH', {lengthUnit})}
              value={width}
              required={!!(width || height || depth)}
              type='number'
              onChange={this.dimensionChangeHandler('width')}
              format='2'
              step='0.01'
              min='0'
              max='1000000'/>
          </div>
          <div>
            <TextField
              name='height'
              label={translate('ship.boxes.HEIGHT', {lengthUnit})}
              value={height}
              required={!!(width || height || depth)}
              type='number'
              onChange={this.dimensionChangeHandler('height')}
              format='2'
              step='0.01'
              min='0'
              max='1000000'/>
          </div>
          <div>
            <TextField
              style={{marginLeft: '10%'}}
              name='depth'
              label={translate('ship.boxes.DEPTH', {lengthUnit})}
              value={depth}
              required={!!(width || height || depth)}
              type='number'
              onChange={this.dimensionChangeHandler('depth')}
              format='2'
              step='0.01'
              min='0'
              max='1000000'/>
          </div>
        </div>}
        {this.props.displayReference &&
          <TextField
            id='shipping-reference'
            name='shipment-reference'
            value={this.props.shippingLabelReference}
            label={translate('ship.SHIPMENT_REFERENCE')}
            type='text'
            helpText={translate('ship.REFERENCE_HELP')}
            onChange={this.onReferenceChange}/>
        }
      </div>
    )
  }
}

export default withLocalize(connect(
  (state, ownProps) => {
    return {
      translate: getTranslate(state.localize),
      displayReference: state.settings && state.settings.customShip && state.settings.customShip.displayReference,
      customShip: state.settings?.customShip,
      shippingLabelReference: state.shipping.shipments[0].shippingLabelReference,
      weight: state.shipping.shipments[0].weight,
      weightUnits: state.logisticsLocation.currentLocation && state.logisticsLocation.currentLocation.localeSettings.weightUnits,
      lengthUnits: state.logisticsLocation.currentLocation?.localeSettings.lengthUnits,
      width: state.shipping.shipments[0].width,
      height: state.shipping.shipments[0].height,
      depth: state.shipping.shipments[0].depth,
      shipmentId: state.shipping.shipments[0].shipmentId
    }
  }
)(ShippingOptions))
