import * as languageService from '../language/languageService'
import * as shippingOptionsProxy from './shippingOptionsProxy'
import { alert, clear } from '../../redux/alert/actions'
import moment from 'moment'

export async function getShippingOptions(shippingAddress, returnAddress, weightInLbs) {
  try {
    let shippingOptions = await shippingOptionsProxy.getShippingOptions(shippingAddress, returnAddress, weightInLbs, moment().format())

    if (!shippingOptions || shippingOptions.length < 1) {
      alert({
        message: languageService.format('createLabel.NO_SHIPPING_OPTIONS'),
        level: 'danger',
        slot: 'recommendation'
      })
      return
    }

    shippingOptions.sort((optionOne, optionTwo) => {
      return optionOne.rates.discountedPrice - optionTwo.rates.discountedPrice
    })

    clear({ level: 'danger', slot: 'recommendation' })
    return shippingOptions
  } catch (err) {
    let cause = ''
    if (err.data) {
      if (err.data.causes && err.data.causes[0]) {
        cause = checkError(err.data.causes[0])
      }

      if (!cause) {
        cause = checkError(err.data)
      }
    }

    if (cause) {
      alert({ message: cause, level: 'danger', slot: 'recommendation' })
    } else {
      alert({
        message: languageService.format('createLabel.ERROR_SHIPPING_OPTIONS'),
        level: 'danger',
        slot: 'recommendation'
      })
    }
  }
}

function retrieveErrorType(type) {
  let slashIndex = type.lastIndexOf('/')
  return type.substring(slashIndex + 1)
}

function checkError(err) {
  if (err.type && retrieveErrorType(err.type) === 'invalid-postal-code') {
    return languageService.format('createLabel.INVALID_POSTAL_CODE')
  } else if (err.detail) {
    return err.detail
  } else if (err.title) {
    return err.title
  }
}
