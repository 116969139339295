import React, {Component} from 'react'
import { withLocalize, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import { Select, TextField } from '@cimpress/react-components'
import * as shippingActions from '../../../redux/shipping/actions'
import states from '../../../components/addressForm/state.json'
import createLoqateControl from '../../../lib/common/loqate'

export class ShippingAddress extends Component {
  constructor(props) {
    super(props)
    autobind(this)
  }

  onNameChange (e) {
    shippingActions.dispatchUpdateName(e.target.value, this.props.shipmentId)
  }
  onCompanyChange (e) {
    shippingActions.dispatchUpdateCompany(e.target.value, this.props.shipmentId)
  }
  onStreetChange(e) {
    shippingActions.dispatchUpdateStreet(e.target.value, this.props.shipmentId)
  }
  onStreet2Change (e) {
    shippingActions.dispatchUpdateStreet2(e.target.value, this.props.shipmentId)
  }
  onPostalCodeChange(e) {
    shippingActions.dispatchUpdatePostalCode(e.target.value, this.props.shipmentId)
  }
  onLocalityChange (e) {
    shippingActions.dispatchUpdateLocality(e.target.value, this.props.shipmentId)
  }
  onRegionChange(e) {
    shippingActions.dispatchUpdateRegion(e.target.value, this.props.shipmentId)
  }
  onCountryChange(country) {
    shippingActions.dispatchUpdateCountry(country, this.props.shipmentId)
    let { requirePostalCode } = this.props.shippableCountries.find(shippableCountry => shippableCountry.value === country)
    shippingActions.dispatchRequirePostalCode(requirePostalCode, this.props.shipmentId)
  }
  onPhoneChange (e) {
    shippingActions.dispatchUpdatePhone(e.target.value, this.props.shipmentId)
  }

  stateCodesList() {
    return states[this.props.country]
  }

  setDefaultCountry () {
    if (this.props.shippableCountries) {
      let found = this.props.shippableCountries.find(c => c.value === this.props.defaultCountry)
      if (found) {
        this.onCountryChange(found.value)
      }
    }
  }

  async componentDidMount() {
    if (!this.props.country) {
      this.setDefaultCountry()
    }
    this.setUpLoqate()
  }

  setUpLoqate() {
    let loqateControl = createLoqateControl(this.props.shippableCountries.map(shippableCountry => shippableCountry.value))

    loqateControl.listen('populate', (address) => {
      shippingActions.dispatchUpdateLocality(address.City, this.props.shipmentId)
      shippingActions.dispatchUpdateStreet(address.Line1, this.props.shipmentId)
      shippingActions.dispatchUpdateStreet2(address.Line2, this.props.shipmentId)
      shippingActions.dispatchUpdatePostalCode(address.PostalCode, this.props.shipmentId)
      shippingActions.dispatchUpdateRegion(address.Province, this.props.shipmentId)
      this.onCountryChange(address.CountryIso2)
    })

    loqateControl.listen('country', ({ iso2 } = country) => {
      this.onCountryChange(iso2)
    })
  }

  render() {
    const { translate } = this.props
    return (
      <div>
        <label className='section-description'>{translate('createLabel.ENTER_SHIPPING_ADDRESS')}</label>
        <TextField
          id='shipping-address-name'
          name='name'
          value={this.props.name}
          required={true}
          type='text'
          autoComplete="off"
          label={translate('createLabel.address.placeholder.NAME')}
          onChange={this.onNameChange} />
        <TextField
          id='shipping-address-company'
          name='company'
          value={this.props.company}
          autoComplete="off"
          label={translate('createLabel.address.placeholder.COMPANY')}
          type='text'
          onChange={this.onCompanyChange} />
        <TextField
          id='shipping-address-country'
          name='countryCode'
          value={this.props.shippableCountries.find(shippableCountry => shippableCountry.value === this.props.country)?.label}
          autoComplete="off"
          label={translate('createLabel.address.placeholder.COUNTRY')}
          type='text'
          disabled={this.props.shippableCountries && this.props.shippableCountries.length === 1}
          style={{ marginTop: '4%' }}
          onChange={(e) => this.onCountryChange(e.target.value)} />
        <TextField
          id='shipping-address-street'
          name='street'
          value={this.props.street}
          label={translate('createLabel.address.placeholder.ADDRESS_1')}
          required={true}
          type='text'
          autoComplete="off"
          style={{ zIndex: '10' }}
          onChange={this.onStreetChange} />
        <TextField
          id='shipping-address-street2'
          name='street2'
          value={this.props.street2}
          autoComplete="off"
          label={translate('createLabel.address.placeholder.ADDRESS_2')}
          type='text'
          style={{ marginTop: '4%' }}
          onChange={this.onStreet2Change} />
        <div className='row'>
          <div className='col-sm-6'>
            <TextField
              id='shipping-address-locality'
              data-address-role='city'
              name='locality'
              value={this.props.locality}
              autoComplete="off"
              label={translate('createLabel.address.placeholder.CITY')}
              required={true}
              type='text'
              onChange={this.onLocalityChange} />
          </div>
          <div className='col-sm-6'>
            <TextField
              id='shipping-address-postalCode'
              data-address-role='postalCode'
              name='postalCode'
              value={this.props.postalCode}
              autoComplete="off"
              label={translate('createLabel.address.placeholder.POSTAL_CODE')}
              required={this.props.requirePostalCode}
              type='text'
              onChange={this.onPostalCodeChange} />
          </div>
        </div>
        {this.stateCodesList() ?
        <Select
          containerclassname='shipping-address-region'
          id='shipping-address-region-select'
          label={translate('createLabel.address.placeholder.REGION')}
          name='region'
          value={this.stateCodesList().find(state => state.value === this.props.region)}
          required={true}
          onChange={({value}) => this.onRegionChange({target: {value}})}
          options={this.stateCodesList()}/> :
        <TextField
          containerclassname='shipping-address-region'
          id='shipping-address-region-text'
          data-address-role='state'
          autoComplete="off"
          label={translate('createLabel.address.placeholder.REGION')}
          name='region'
          value={this.props.region}
          required={false}
          onChange={this.onRegionChange} />}
        { !this.props.hidePhone && <TextField
          id='shipping-address-phone'
          name='phoneNumber'
          value={this.props.phone}
          autoComplete="off"
          label={translate('createLabel.address.placeholder.PHONE')}
          type='text'
          onChange={this.onPhoneChange}
        />}
      </div>
    )
  }
}

export default withLocalize(connect(
  (state, ownProps) => {
    return {
      translate: getTranslate(state.localize),
      name: state.shipping.shipments[0].shippingAddress.name,
      ccmEnabled: state.fulfillmentLocation && state.fulfillmentLocation.ccmEnabled,
      company: state.shipping.shipments[0].shippingAddress.company,
      street: state.shipping.shipments[0].shippingAddress.street,
      street2: state.shipping.shipments[0].shippingAddress.street2,
      postalCode: state.shipping.shipments[0].shippingAddress.postalCode,
      requirePostalCode: state.shipping.shipments[0].requirePostalCode,
      locality: state.shipping.shipments[0].shippingAddress.locality,
      region: state.shipping.shipments[0].shippingAddress.region,
      country: state.shipping.shipments[0].shippingAddress.countryCode,
      phone: state.shipping.shipments[0].shippingAddress.phone,
      shipmentId: state.shipping.shipments[0].shipmentId,
      shippableCountries: state.fulfillmentLocation.shippableCountries,
      hidePhone: state.settings && state.settings.customShip && state.settings.customShip.hidePhoneNumber,
      defaultCountry: state.settings && state.settings.customShip && state.settings.customShip.defaultCountryCode
    }
  }
)(ShippingAddress))
