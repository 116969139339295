export default (state = {}, action) => {
  switch (action.type) {
    case 'UPLOAD_SHIPMENTS':
      return {
        ...state,
        shipments: action.shipments
      }
    case 'FILE':
      return {
        ...state,
        file: action.file
      }
    case 'SELECTED_CARRIER_SERVICE_KEY':
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment.shipmentId === action.shipmentId) {
            shipment.selectedCarrierServiceKey = action.carrierServiceKey
          }
          return shipment
        })
      }
    case 'SHIPPING_OPTIONS':
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment.shipmentId === action.shipmentId) {
            shipment.shippingOptions = action.shippingOptions
          }
          return shipment
        })
      }
    case 'UPDATE_CARRIER_RECOMMENDATION_IN_PROGRESS':
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment.shipmentId === action.shipmentId) {
            shipment.carrierRecommendationInProgress = action.carrierRecommendationInProgress
          }
          return shipment
        })
      }
    case 'SHIPPING_IN_PROGRESS':
      return {
        ...state,
        shippingInProgress: action.value
      }
    case 'PROMPT_FOR_CUSTOM_SHIPPING_ADDRESS':
      return {
        ...state,
        promptForCustomAddress: action.value
      }

    case 'CLEAR_SHIPPING':
      return {
        shipments: [{
          shipmentId: 1,
          shippingAddress: {
            name: undefined,
            company: undefined,
            street: undefined,
            street2: undefined,
            postalCode: undefined,
            locality: undefined,
            region: undefined,
            countryCode: undefined,
            phone: undefined
          },
          weight: undefined,
          requirePostalCode: true,
          carrierRecommendationInProgress: false,
          shippingOptions: [],
          selectedCarrierServiceKey: ''
        }],
        file: undefined,
        promptForCustomAddress: false,
        shippingInProgress: false
      }

    case 'UPDATE_NAME':
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment.shipmentId === action.shipmentId) {
            shipment.shippingAddress.name = action.name
          }
          return shipment
        })
      }

    case 'UPDATE_COMPANY':
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment.shipmentId === action.shipmentId) {
            shipment.shippingAddress.company = action.company
          }
          return shipment
        })
      }

    case 'UPDATE_STREET':
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment.shipmentId === action.shipmentId) {
            shipment.shippingAddress.street = action.street
          }
          return shipment
        })
      }

    case 'UPDATE_STREET2':
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment.shipmentId === action.shipmentId) {
            shipment.shippingAddress.street2 = action.street2
          }
          return shipment
        })
      }

    case 'UPDATE_POSTAL_CODE':
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment.shipmentId === action.shipmentId) {
            shipment.shippingAddress.postalCode = action.postalCode
          }
          return shipment
        })
      }

    case 'UPDATE_REQUIRE_POSTAL_CODE':
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment.shipmentId === action.shipmentId) {
            shipment.requirePostalCode = action.requirePostalCode
          }
          return shipment
        })
      }

    case 'UPDATE_LOCALITY':
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment.shipmentId === action.shipmentId) {
            shipment.shippingAddress.locality = action.locality
          }
          return shipment
        })
      }

    case 'UPDATE_REGION':
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment.shipmentId === action.shipmentId) {
            shipment.shippingAddress.region = action.region
          }
          return shipment
        })
      }

    case 'UPDATE_COUNTRY':
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment.shipmentId === action.shipmentId) {
            shipment.shippingAddress.countryCode = action.countryCode
          }
          return shipment
        })
      }

    case 'UPDATE_PHONE':
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment.shipmentId === action.shipmentId) {
            shipment.shippingAddress.phone = action.phone
          }
          return shipment
        })
      }

    case 'UPDATE_WEIGHT':
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment.shipmentId === action.shipmentId) {
            shipment.weight = action.weight
          }
          return shipment
        })
      }

    case 'UPDATE_DIMENSIONS':
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment.shipmentId === action.shipmentId) {
            shipment.width = action.width ?? shipment.width
            shipment.height = action.height ?? shipment.height
            shipment.depth = action.depth ?? shipment.depth
          }

          return shipment
        })
      }
    case 'UPDATE_PAYABLE_TO':
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment.shipmentId === action.shipmentId) {
            shipment.payableBy = action.payableBy
          }
          return shipment
        })
      }

    case 'UPDATE_LABEL_REFERENCE':
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment.shipmentId === action.shipmentId) {
            shipment.shippingLabelReference = action.shippingLabelReference
          }
          return shipment
        })
      }

    default: return state
  }
}
