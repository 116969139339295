import store from '../index'

export const dispatchShipments = (shipments) => {
  store.dispatch({ type: 'UPLOAD_SHIPMENTS', shipments })
}

export const clearShipments = () => {
  store.dispatch({ type: 'UPLOAD_SHIPMENTS', shipments: [] })
}

export const dispatchFile = (file) => {
  store.dispatch({ type: 'FILE', file })
}

export const dispatchSelectedCarrierServiceKey = (carrierServiceKey, shipmentId) => {
  store.dispatch({ type: 'SELECTED_CARRIER_SERVICE_KEY', shipmentId, carrierServiceKey })
}

export const startShippingOptionsRetrieval = (shipmentId) => {
  store.dispatch({ type: 'UPDATE_CARRIER_RECOMMENDATION_IN_PROGRESS', shipmentId, carrierRecommendationInProgress: true })
  store.dispatch({ type: 'SHIPPING_OPTIONS', shipmentId, shippingOptions: [] })
  store.dispatch({ type: 'SELECTED_CARRIER_SERVICE_KEY', shipmentId, selectedCarrierServiceKey: '' })
}

export const finishShippingOptionsRetrieval = (shippingOptions, shipmentId) => {
  store.dispatch({ type: 'SHIPPING_OPTIONS', shipmentId, shippingOptions })
  if (shippingOptions.length > 0) {
    store.dispatch({ type: 'SELECTED_CARRIER_SERVICE_KEY', shipmentId, carrierServiceKey: shippingOptions[0].service.carrierServiceKey })
  } else {
    store.dispatch({ type: 'SELECTED_CARRIER_SERVICE_KEY', shipmentId, carrierServiceKey: '' })
  }

  store.dispatch({ type: 'UPDATE_CARRIER_RECOMMENDATION_IN_PROGRESS', shipmentId, carrierRecommendationInProgress: false })
}

export const dispatchStartShipping = () => {
  store.dispatch({ type: 'SHIPPING_IN_PROGRESS', value: true })
}

export const dispatchEndShipping = () => {
  store.dispatch({ type: 'SHIPPING_IN_PROGRESS', value: false })
}

export const dispatchOpenPromptForCustomAddress = () => {
  store.dispatch({ type: 'PROMPT_FOR_CUSTOM_SHIPPING_ADDRESS', value: true })
}

export const dispatchClosePromptForCustomAddress = () => {
  store.dispatch({ type: 'PROMPT_FOR_CUSTOM_SHIPPING_ADDRESS', value: false })
}

export const dispatchClearShipping = () => {
  store.dispatch({ type: 'CLEAR_SHIPPING' })
}

export const dispatchShippingOptions = (shippingOptions, shipmentId) => {
  store.dispatch({ type: 'SHIPPING_OPTIONS', shippingOptions, shipmentId })
}

export const dispatchUpdateName = (name, shipmentId) => {
  store.dispatch({ type: 'UPDATE_NAME', name, shipmentId })
}

export const dispatchUpdateCompany = (company, shipmentId) => {
  store.dispatch({ type: 'UPDATE_COMPANY', company, shipmentId })
}

export const dispatchUpdateStreet = (street, shipmentId) => {
  store.dispatch({ type: 'UPDATE_STREET', street, shipmentId })
}

export const dispatchUpdateStreet2 = (street2, shipmentId) => {
  store.dispatch({ type: 'UPDATE_STREET2', street2, shipmentId })
}

export const dispatchUpdatePostalCode = (postalCode, shipmentId) => {
  store.dispatch({ type: 'UPDATE_POSTAL_CODE', postalCode, shipmentId })
}

export const dispatchRequirePostalCode = (requirePostalCode, shipmentId) => {
  store.dispatch({ type: 'UPDATE_REQUIRE_POSTAL_CODE', requirePostalCode, shipmentId })
}

export const dispatchUpdateLocality = (locality, shipmentId) => {
  store.dispatch({ type: 'UPDATE_LOCALITY', locality, shipmentId })
}

export const dispatchUpdateRegion = (region, shipmentId) => {
  store.dispatch({ type: 'UPDATE_REGION', region, shipmentId })
}

export const dispatchUpdateCountry = (countryCode, shipmentId) => {
  store.dispatch({ type: 'UPDATE_COUNTRY', countryCode, shipmentId })
}

export const dispatchUpdatePhone = (phone, shipmentId) => {
  store.dispatch({ type: 'UPDATE_PHONE', phone, shipmentId })
}

export const dispatchUpdateLabelReference = (reference, shipmentId) => {
  store.dispatch({ type: 'UPDATE_LABEL_REFERENCE', reference, shipmentId })
}

export const dispatchUpdateWeight = (weight, shipmentId) => {
  store.dispatch({ type: 'UPDATE_WEIGHT', weight, shipmentId })
}

export const dispatchUpdateDimensions = (dimensions, shipmentId) => {
  store.dispatch({ ...dimensions, type: 'UPDATE_DIMENSIONS', shipmentId })
}

export const dispatchUpdatePayableBy = (payableBy, shipmentId) => {
  store.dispatch({ type: 'UPDATE_PAYABLE_TO', payableBy, shipmentId })
}

export const dispatchUpdateCarrierRecommendationInProgress = (carrierRecommendationInProgress, shipmentId) => {
  store.dispatch({ type: 'UPDATE_CARRIER_RECOMMENDATION_IN_PROGRESS', carrierRecommendationInProgress, shipmentId })
}
