import appConfig from '../../app-config.json'

export default function createLoqateControl(allowedCountries) {
  const fields = [
    { element: "shipping-address-street", field: "Line1", mode: pca.fieldMode.SEARCH | pca.fieldMode.POPULATE },    
    { element: "shipping-address-street2", field: "Line2", mode: pca.fieldMode.POPULATE },
    { element: "shipping-address-locality", field: "City", mode: pca.fieldMode.POPULATE },
    { element: "region", field: "ProvinceName", mode: pca.fieldMode.POPULATE },
    { element: "shipping-address-postalCode", field: "PostalCode", mode: pca.fieldMode.SEARCH | pca.fieldMode.POPULATE },
    { element: "shipping-address-country", field: "CountryName", mode: pca.fieldMode.COUNTRY }
  ]

  let countries = allowedCountries && { codesList: allowedCountries.join(',') }
  const config = {
    key: appConfig.LOQATE_API_KEY,
    countries
  }

  return new window.pca.Address(fields, config)
}
