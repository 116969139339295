import { omit } from 'lodash'
import * as customizrProxy from './customizrProxy'

export async function getUserSettings() {
  try {
    return await customizrProxy.getUserSettings()
  } catch (error) {
    if (error.status === 404) return {}
    throw error
  }
}

export async function updateUserSettings(newSettings) {
  const savedSettings = await customizrProxy.getUserSettings()
  const settings = {
    ...savedSettings,
    ...newSettings
  }
  return customizrProxy.updateUserSettings(settings)
}

export async function deleteUserSettings(settingName) {
  const savedSettings = await customizrProxy.getUserSettings()
  const newSettings = omit(savedSettings, settingName)
  return customizrProxy.updateUserSettings(newSettings)
}
