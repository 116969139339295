import * as authenticationProxy from '../authentication/authenticationProxy'
import * as sapiProxy from '../sapi/sapiProxy'
import store from '../../redux'
import { unit } from 'mathjs'
import * as languageService from '../language/languageService'
import { formatErrorMessage } from '../common/errorFormattingService'
import config from '../../app-config.json'

export function search (search) {
  try {
    return authenticationProxy.makeAuthenticatedShipmanRequest({
      uri: '/api/v1/shipments',
      params: search,
      json: true
    })
  } catch(ignore){
    return
  }
}

export async function createShipment(shipment) {
  const { fulfillmentLocation: { currentLocation: { id: locationKey } } } = store.getState()
  const {
    packages,
    shippingAddress,
    returnAddress = {},
    carrierServiceKey,
    trackingItems
  } = shipment
  const requestHeaders = {
    'Unique-Shipment-Identifier': shipment.id
  }
  const request = {
    fulfillmentLocationId: locationKey,
    carrierServiceKey,
    shipFromAddress: formatAddress(returnAddress),
    destinationAddress: formatAddress(shippingAddress),
    packages: packages.map(({
      reference,
      dimensions: {x, y, z, unit: lengthUnit} = {},
      weight: {value: weight, unit: weightUnit} = {},
    }) => ({
      weight: `${unit(weight, weightUnit).toNumber('kg')} kg`,
      dimensions: lengthUnit && [x, y, z].map(length => `${unit(length, lengthUnit).toNumber('cm')} cm`),
      trackingId: trackingItems.filter(({packageRef: r}) => reference === r).map(({number}) => number).find(() => true),
      trackingUrl: trackingItems.filter(({packageRef: r}) => reference === r).map(({url}) => url).find(() => true),
    })),
    pickupDateTime: shipment.pickup && shipment.pickup.dateTime,
    reference: shipment.id,
    metadata: {
      ...shipment.metadata,
      createdBy: 'Shipping Console V3 Kiosk'
    },
    carrierServiceOptions: shipment.carrierServiceOptions,
    links: {
      shippingAPIShipmentV3: {
        rel: 'shippingAPIShipmentV3',
        href: `${config.SAPI_BASE_URL || 'https://stg.api.shipping.cimpress.io'}/v3/shipments/${shipment.id}`
      }
    }
  }
  try {
    await authenticationProxy.makeAuthenticatedShipmanRequest({
      uri: '/api/v1/shipments',
      method: 'POST',
      headers: requestHeaders,
      data: request,
    })
  } catch(err) {
    await sapiProxy.cancelShipment(shipment.id)
    throw new Error(languageService.format('createLabel.ERROR_CREATE_SHIPMENT')+ ' ' + formatErrorMessage(err))
  }
}

function formatAddress(address) {
  const {locality, countryCode, postalCode, region, name, phone, street: street1, street2, company} = address

  let formattedAddress = {
    name,
    street1,
    locality,
    region,
    postalCode,
    countryCode,
    phone
  }

  if(street2) {
    formattedAddress.street2 = street2
  }

  if(company) {
    formattedAddress.company = company
  }

  return formattedAddress
}
