import * as authenticationProxy from '../authentication/authenticationProxy'

export function getUserSettings() {
  const request = {
    method: 'GET',
    url: '/v1/resources/shipping-console/settings',
    cache: { maxAge: 1000 }
  }
  return authenticationProxy.makeAuthenticatedCustomizrRequest(request)
}

export function updateUserSettings(settings) {
  const request = {
    method: 'PUT',
    url: '/v1/resources/shipping-console/settings',
    data: settings
  }
  return authenticationProxy.makeAuthenticatedCustomizrRequest(request)
}
