export default {
  activeFulfillmentLocationId: 'nqjnhpre',
  currentLocation: {
    name: 'Shipping Console Kiosk - Waltham Mail Room',
    id: 'nqjnhpre',
    capabilities: {
      'create-custom-shipment': {
        id: 'create-custom-shipment'
      }
    }
  },
  shipChoices: [
    'customShip'
  ],
  loadingCarrierServices: false,
  ccmEnabled: true,
  shippableCountries: [
    {
      label: 'United States',
      value: 'US',
      requirePostalCode: true
    }
  ]
}
