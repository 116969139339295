import * as authenticationProxy from '../../authentication/authenticationProxy'

export async function v3 (body) {
  const request = {
    method: 'POST',
    url: '/v3/shipments',
    data: body
  }

  return authenticationProxy.makeAuthenticatedSapiRequest(request)
}
