import * as authenticationProxy from '../authentication/authenticationProxy'

export function getReturnAddresses() {
  let request = {
    method: 'GET',
    url: `/v1/resources/shipping-console/settings`
  }
  return authenticationProxy.makeAuthenticatedCustomizrRequest(request)
}

export function addOrUpdateReturnAddresses(returnAddresses) {
  let request = {
    method: 'PUT',
    url: `/v1/resources/shipping-console/settings`,
    data: {
      returnAddresses: returnAddresses
    }
  }
  return authenticationProxy.makeAuthenticatedCustomizrRequest(request)
}

export function deleteReturnAddresses() {
  let request = {
    method: 'DELETE',
    url: `/v1/resources/shipping-console/settings`
  }
  return authenticationProxy.makeAuthenticatedCustomizrRequest(request)
}