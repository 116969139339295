import React from 'react'

export function formatErrorMessage (err) {
  let message = ''
  if (!err) {
    return message
  }

  if (React.isValidElement(err)) {
    return err
  }

  if (err.message) {
    message += err.message
  }

  if (err.moreInfo && err.moreInfo.length) {
    message += ' ' + err.moreInfo.join(', ')
  }

  if (typeof err === 'string') {
    message = err
  }

  return message
}
