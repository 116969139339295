import store from '../../redux/index'
import { merge } from 'lodash'
import * as formatter from './shippingFormatterService'
import * as sapi from '../sapi/ship/v3'
import * as sapiv3ShippingFormatterService from '../sapi/ship/sapiv3ShippingFormatterService'
import * as labelService from '../shipping/labelService'
import * as shipmanProxy from '../shipman/shipmanProxy'

export async function shipViaSapiAndShipman() {
  const { shipping } = store.getState()

  return  Promise.all(shipping.shipments.map(async (shipment) => {
    let shipResult
    let labels
    let shipmentResult
    try {
      const request = sapiv3ShippingFormatterService.formatSapiv3ShipRequest(shipment)
      shipResult = await sapi.v3(request)
      shipmentResult = merge(
        {},
        shipResult,
        { shippingAddress: shipment.shippingAddress },
        { shippingOptions: shipment.shippingOptions },
        { selectedCarrierServiceKey: shipment.selectedCarrierServiceKey },
        {
          trackingInfo: {
            trackingId: shipResult.trackingItems && shipResult.trackingItems[0] && shipResult.trackingItems[0].number,
            trackingUrl: shipResult.trackingItems && shipResult.trackingItems[0] && shipResult.trackingItems[0].url
          }
        })
    } catch (err) {
      return {
        error: Error(formatter.formatErrors(err))
      }
    }
    try {
      labels = (await Promise.all([labelService.retrieveLabelContent(shipmentResult), shipmanProxy.createShipment(shipmentResult)]))[0]
      return {
        labels,
        shipment: shipmentResult
      }
    } catch (err) {
      return {
        error: err
      }
    }
  }))
}
