import React, { Component } from 'react'
import { connect } from 'react-redux'
import { shapes } from '@cimpress/react-components'
const { Spinner } = shapes

import ReturnAddressForm from './view/returnAddressForm'

export class ReturnAddress extends Component {

  render() {
    if (this.props.loadingInProgress) {
      return (
        <div className='flex justify-center'>
          <Spinner />
        </div>
      )
    }

    return (
      <div className='row'>
        <ReturnAddressForm />
      </div>
    )
  }
}

export default connect(
  (state) => {
    return {
      loadingInProgress: state.returnAddress && state.returnAddress.loadingInProgress,
    }
  })(ReturnAddress)
