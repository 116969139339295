import store from '../../redux/index'
import * as localize from 'react-localize-redux'

export function format (translationId, fieldNames, fieldValues) {
  let state = store.getState()
  if (state.localize) {
    const translate = localize.getTranslate(state.localize)
    return translate(translationId, formatParams(fieldNames, fieldValues))
  }
  return `No translation found. ${translationId}`
}

export function translate (translationId, values) {
  const translate = localize.getTranslate(store.getState().localize)
  return translate(translationId, values)
}

export function getCurrentLanguage () {
  let state = store.getState()
  return state.localize ? localize.getActiveLanguage(state.localize).code : 'en'
}

export function formatParams (fieldNames, fieldValues) {
  var interpolateParams = {}
  if (fieldNames) {
    for (var i = 0; i < fieldNames.length; i++) {
      interpolateParams[fieldNames[i]] = fieldValues[i]
    }
  }
  return interpolateParams
}
