export default {
  shipments: [{
    shipmentId: 1,
    shippingAddress: {
      name: undefined,
      company: undefined,
      street: undefined,
      street2: undefined,
      postalCode: undefined,
      locality: undefined,
      region: undefined,
      countryCode: undefined,
      phone: undefined
    },
    weight: undefined,
    requirePostalCode: true,
    carrierRecommendationInProgress: false,
    shippingOptions: [],
    selectedCarrierServiceKey: ''
  }],
  file: undefined,
  promptForCustomAddress: false,
  shippingInProgress: false
}
