import store from '../../redux/index'

export function formatErrors ({message, moreInfo}) {
  return `${message ? message + ' ' : ''}${moreInfo && moreInfo.length ? moreInfo.join('; ') : ''}`
}

export function formatCustomShipRequest(shipment) {
  const { fulfillmentLocation: { currentLocation: { id: fulfillmentLocation } },
    logisticsLocation: {currentLocation: {localeSettings: {weightUnits, lengthUnits} } }, settings: { customShip = {} } } = store.getState()
  const createLabelRequest = Object.assign({}, shipment, {fulfillmentLocation}, {weightUnits, lengthUnits})

  let address = {
    name: createLabelRequest.shippingAddress.name,
    company: createLabelRequest.shippingAddress.company,
    street: createLabelRequest.shippingAddress.street,
    street2: createLabelRequest.shippingAddress.street2,
    locality: createLabelRequest.shippingAddress.locality,
    region: createLabelRequest.shippingAddress.region,
    postalCode: createLabelRequest.shippingAddress.postalCode,
    countryCode: createLabelRequest.shippingAddress.countryCode
  }
  if (createLabelRequest.shippingAddress.phone) {
    address.phone = createLabelRequest.shippingAddress.phone
  }

  let weightField = {
    id: getWeightId(createLabelRequest.weightUnits),
    value: createLabelRequest.weight
  }
  let carrierService = {
    id: 'carrier-service',
    value: createLabelRequest.selectedCarrierServiceKey
  }
  let dimensionsFields = getDimensionFields(lengthUnits || 'cm', shipment.width, shipment.height, shipment.depth)
  let shippingOptions = [weightField, carrierService, ...dimensionsFields]

  let formattedRequest =  {
    fulfillmentLocationId: createLabelRequest.fulfillmentLocation,
    shippingChoiceId: 'sapi',
    shippingChoiceConfig: {
      fields: shippingOptions
    },
    shippingAddress: address
  }

  if (customShip.showPayableByOptions) {
    formattedRequest.metadata = {
      payableBy: createLabelRequest.payableBy || 'personal'
    }
  }

  if (createLabelRequest.shippingLabelReference) {
    formattedRequest.shippingLabelReference = createLabelRequest.shippingLabelReference.trim()
  }

  return formattedRequest
}

function getDimensionFields(lengthUnits, width, height, depth) {
  const toCentimeters = 2.54
  let lengthConversion = 1.0
  let formattedLengthUnits = 'cm'

  if (['in', 'inch', 'inches'].includes(lengthUnits.toLowerCase())) {
    lengthConversion = toCentimeters
  }

  let dimensionsFields = []
  if (width && height && depth) {
    let widthField = {
      id: `width-in-${formattedLengthUnits}`,
      value: width * lengthConversion
    }
    let heightField = {
      id: `height-in-${formattedLengthUnits}`,
      value: height * lengthConversion
    }
    let depthField = {
      id: `depth-in-${formattedLengthUnits}`,
      value: depth * lengthConversion
    }
    dimensionsFields = [widthField, heightField, depthField]
  }

  return dimensionsFields
}

function getWeightId (weightUnits) {
  var weightId = ''
  if (['kg', 'kgs', 'kilos', 'kilo', 'kilogram', 'kilograms'].includes(weightUnits.toLowerCase())) {
    weightId = 'weight-in-kilograms'
  } else if (['lbs', 'lb', 'pound', 'pounds'].includes(weightUnits.toLowerCase())) {
    weightId = 'weight-in-pounds'
  } else if (['g', 'gm', 'gms', 'gram', 'grams'].includes(weightUnits.toLowerCase())) {
    weightId = 'weight-in-grams'
  }

  return weightId
}
