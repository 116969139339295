export default (state = {}, action) => {
  const { type, message, key, slot, dismissible } = action

  switch (type) {
    case 'ADD_DANGER_ALERT':
      return {
        ...state,
        alerts: state.alerts.concat([{ message, level: 'danger', key, slot, dismissible }])
      }
    case 'ADD_WARNING_ALERT':
      return {
        ...state,
        alerts: state.alerts.concat([{ message, level: 'warning', key, slot, dismissible }])
      }
    case 'ADD_INFO_ALERT':
      return {
        ...state,
        alerts: state.alerts.concat([{ message, level: 'info', key, slot, dismissible }])
      }
    case 'ADD_SUCCESS_ALERT':
      return {
        ...state,
        alerts: state.alerts.concat([{ message, level: 'success', key, slot, dismissible }])
      }
    case 'REMOVE_ALERT':
      return {
        ...state,
        alerts: state.alerts.filter(alert => (alert.key !== action.key))
      }
    default: return state
  }
}
