// Import dependencies
import 'moment'
import 'es6-shim'
import 'bootstrap'
import ReactDOM from 'react-dom'
import React from 'react'
import $ from 'jquery'
import * as FullStory from '@fullstory/browser'

import App from './modules/app/Root'
FullStory.init({ orgId: '97CT5' })

window.$ = window.jQuery = $

$(() => {
  const rootElement = document.getElementById('root')
  if (rootElement) {
    ReactDOM.render(<App />, rootElement)
  }
})
