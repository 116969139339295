const math = require('mathjs')
import store from '../../redux/index'

export function stripUnit(measurement) {
  return parseFloat(measurement.substring(0, measurement.indexOf(' ')))
}

export function parseUnit(measurement) {
  return measurement.substring(measurement.indexOf(' ') + 1)
}

export function formatStringLength(measurement) {
  if (!measurement) return
  return parseFloat(convertToLocationLength(stripUnit(measurement), parseUnit(measurement)).toFixed(2))
}

export function formatStringWeight(measurement) {
  if (!measurement) return
  return parseFloat(convertToLocationWeight(stripUnit(measurement), parseUnit(measurement)).toFixed(2))
}

export function convertToLocationLength(value, currentUnit) {
  let lengthUnit = getLengthUnit()
  if (!lengthUnit) {
    throw new Error('Length conversion failed. No length unit specified for current logistics location')
  }
  return math.unit(value, currentUnit).toNumber(lengthUnit)
}

export function convertToLocationWeight(value, currentUnit) {
  let weightUnit = getWeightUnit()
  if (!weightUnit) {
    throw new Error('Weight conversion failed. No weight unit specified for current logistics location')
  }
  return math.unit(value, currentUnit).toNumber(weightUnit)
}

export function convertFromLocationLength(value, newUnit) {
  let lengthUnit = getLengthUnit()
  if (!lengthUnit) {
    throw new Error('Length conversion failed. No length unit specified for current logistics location')
  }
  return math.unit(value, lengthUnit).toNumber(newUnit)
}

export function convertFromLocationWeight(value, newUnit) {
  let weightUnit = getWeightUnit()
  if (!weightUnit) {
    throw new Error('Weight conversion failed. No weight unit specified for current logistics location')
  }
  return math.unit(value, weightUnit).toNumber(newUnit)
}

function getLengthUnit() {
  let logisticsLocation = store.getState().logisticsLocation
  return logisticsLocation && logisticsLocation.currentLocation && logisticsLocation.currentLocation.localeSettings && logisticsLocation.currentLocation.localeSettings.lengthUnits
}

function getWeightUnit() {
  let logisticsLocation = store.getState().logisticsLocation
  return logisticsLocation && logisticsLocation.currentLocation && logisticsLocation.currentLocation.localeSettings && logisticsLocation.currentLocation.localeSettings.weightUnits
}

export function arrayBufferToBase64 (buffer) {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}