import * as customizrProxy from './customizrProxy'

export async function getReturnAddresses() {
  try {
    return await customizrProxy.getReturnAddresses()
  } catch (error) {
    if (error.status === 404) {
      return Promise.resolve()
    }
    return Promise.reject()
  }
}

export async function addOrUpdateReturnAddresses(returnAddresses) {
    return await customizrProxy.addOrUpdateReturnAddresses(returnAddresses)
}

export async function deleteReturnAddresses() {
    return await customizrProxy.deleteReturnAddresses().then(response => response.status)
}