import * as authenticationService from './authenticationService'
import api from '../common/request'
import { merge } from 'lodash'
import config from '../../app-config.json'

export function makeAuthenticatedCustomizrRequest (request) {
  request.url = config.CUSTOMIZR_BASE_URL + (request.uri || request.url)
  return makeAuthenticatedRequest(request)
}

export function makeAuthenticatedCapiRequest (request) {
  request.url = config.CAPI_BASE_URL + (request.uri || request.url)
  return makeAuthenticatedRequest(request)
}

export function makeAuthenticatedFsiRequest (request) {
  request.url = config.FSI_BASE_URL + (request.uri || request.url)
  return makeAuthenticatedRequest(request)
}

export function makeAuthenticatedPureMailRequest (request) {
  request.url = config.PUREMAIL_BASE_URL + (request.uri || request.url)
  return makeAuthenticatedRequest(request)
}

export function makeAuthenticatedQzTrayRequest (request) {
  request.url = config.QZ_TRAY_BASEURL + (request.uri || request.url)
  return makeAuthenticatedRequest(request)
}

export function makeAuthenticatedShippingOptionsRequest (request) {
  request.url = config.SHIPPING_OPTIONS_BASE_URL + (request.uri || request.url)
  return authenticatedRequest(request)
}

export function makeAuthenticatedSapiRequest (request) {
  request.url = config.SAPI_BASE_URL + (request.uri || request.url)
  return makeAuthenticatedRequest(request)
}

export function makeAuthenticatedShipmanRequest (request) {
  request.url = config.SHIPMAN_BASE_URL + (request.uri || request.url)
  return makeAuthenticatedRequest(request)
}

export function makeAuthenticatedRequest (request) {
  return makeAuthenticatedRequestFullResponse(request).then(
    function (res) { return Promise.resolve(res.data) },
    function (err) {
      if (window.newrelic) {
        window.newrelic.setCustomAttribute('url', request.url)
        window.newrelic.noticeError(err)
      }
      if (err.response) {
        return Promise.reject(formatErrorMessage(err.response))
      } else {
        return Promise.reject(err)
      }
    }
  )
}

export function makeUnauthenticatedRequest (request) {
  return api.request(request).then(
    function (res) { return Promise.resolve(res.data) },
    function (err) {
      if (window.newrelic) {
        window.newrelic.setCustomAttribute('url', request.url)
        window.newrelic.noticeError(err)
      }
      if (err.response) {
        return Promise.reject(formatErrorMessage(err.response))
      } else {
        return Promise.reject(err)
      }
    }
  )
}

export function authenticatedRequest (request) {
  return makeAuthenticatedRequestFullResponse(request).then(
    function (res) { return Promise.resolve(res.data) },
    function (err) {
      if (window.newrelic) {
        window.newrelic.setCustomAttribute('url', request.url)
        window.newrelic.noticeError(err)
      }
      if (err.response) {
        return Promise.reject(err.response)
      } else {
        return Promise.reject(err)
      }
    }
  )
}

export function makeAuthenticatedRequestFullResponse (request) {
  return authenticationService.logIn().then(function (isAuthenticated) {
    if (isAuthenticated) {
      let token = authenticationService.getAccessToken()
      if (!request.headers) {
        request.headers = {}
      }
      request.headers.authorization = 'Bearer ' + token

      return api.request(request)
    } else {
      return {}
    }
  })
}

function formatErrorMessage (err) {
  if (err && err.data && err.data.message) {
    return err.data.message
  }
  else if (err && err.data && err.data.error) {
    return err.data.error
  } else {
    const error = new Error(
      'Request failed: ' + err.status + ' ' + err.statusText + ', ' + JSON.stringify(err.data))
    error.status = err.status
    return error
  }
}

export async function request (options) {
  await authenticationService.logIn()
  return api(merge({method: 'get'}, options, {headers: {authorization: `Bearer ${authenticationService.getAccessToken()}`}}))
    .then(({data}) => data, err => {
      if (window.newrelic) {
        window.newrelic.setCustomAttribute('url', request.url)
        window.newrelic.noticeError(err)
      }
      throw err
    })
}
