import React from 'react'
import { Provider } from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import { CssLoader } from '@cimpress/react-components'

import AppRouter from './AppRouter'
import store from '../../redux/index'
import {LocalizeProvider} from 'react-localize-redux'

class Root extends React.Component {
  constructor(props){
    super(props)
  }

  render () {
    return (
      <CssLoader>
        <Provider store={store}>
          <LocalizeProvider store={store}>
            <BrowserRouter>
              <AppRouter {...this.props}/>
            </ BrowserRouter>
          </LocalizeProvider>
        </ Provider>
      </CssLoader>
    )
  }
}


export default Root
