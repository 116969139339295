import { centralizedAuth as CentralizedAuth, UserMetadata } from '@cimpress/simple-auth-wrapper'
import { logOut } from '../../lib/authentication/authenticationService'

import config from '../../../src/app-config.json'

const CLIENT_ID = config.SC_CLIENT_ID || 'x5gI4z4yO9wuf5SIgDMD6bm5iWRDXdOA'

// This should always be true - this is to make sure that
// unit tests pass without having to inject CentralizedAuth everywhere
const auth0 = new CentralizedAuth({
  clientID: CLIENT_ID,
  // These options don't do anything yet, but should be passed to auth0 once CentralizedAuth
  // supports these
  options: {
    allowSignUp: false,
    oidcConformant: true
  },
  expirationOffset: 300,
  emitInitialTokenExpired: false
})

const userMetadata = new UserMetadata(auth0)

auth0.on('tokenExpired', logOut)

export default {
  auth0: auth0,
  userMetadata: userMetadata,
  isAuthenticated: auth0.isLoggedIn(),
  profile: auth0.getProfile()
}
