export default {
  availableLocations: [{
    id: 'e2VBG7DF',
    name: 'Shipping Console Kiosk-Waltham Mail Room',
    address: {
      countryCode: 'US',
      locality: 'Waltham',
      postalCode: '02451',
      region: 'MA',
      street1: '275 Wyman Street',
      street2: ''
    },
    contact: {
      name: '',
      email: '',
      phone: ''
    },
    localeSettings: {
      timezone: 'America/New_York',
      dateFormat: 'MM/DD/YYYY',
      lengthUnits: 'in',
      weightUnits: 'lb',
      currencyCode: 'USD'
    },
    etag: 'db58914b-06ea-4fae-ad71-e9da7b3806a7',
    fulfillmentLocations: [
      {
        id: 'nqjnhpre',
        href: 'https://fulfillmentlocation.trdlnk.cimpress.io/v1/fulfillmentlocations/nqjnhpre'
      }
    ],
    carrierAccounts: [

    ],
    _links: {
      self: {
        href: 'https://configuration.logistics.cimpress.io/v0/locations/e2VBG7DF'
      }
    },
    workingDaysCalendar: {
      id: 'NV6ZOcfX',
      href: 'https://configuration.logistics.cimpress.io/v0/workingDaysCalendars/NV6ZOcfX'
    },
    transitCalendars: {
      mapping: {
        'recs:ups-ground': {
          id: 'KO_xlqWZS',
          href: 'https://configuration.logistics.cimpress.io/v0/workingDaysCalendars/KO_xlqWZS'
        },
        'recs:ups-second-day': {
          id: 'KO_xlqWZS',
          href: 'https://configuration.logistics.cimpress.io/v0/workingDaysCalendars/KO_xlqWZS'
        },
        'recs:ups-three-day-select': {
          id: 'KO_xlqWZS',
          href: 'https://configuration.logistics.cimpress.io/v0/workingDaysCalendars/KO_xlqWZS'
        },
        'recs:ups-next-day-air-saver': {
          id: 'KO_xlqWZS',
          href: 'https://configuration.logistics.cimpress.io/v0/workingDaysCalendars/KO_xlqWZS'
        }
      }
    },
    pickupCalendars: {
      mapping: {
        'recs:ups-ground': {
          id: '0t1gGcyw',
          href: 'https://configuration.logistics.cimpress.io/v0/pickupCalendars/0t1gGcyw'
        },
        'recs:ups-second-day': {
          id: '0t1gGcyw',
          href: 'https://configuration.logistics.cimpress.io/v0/pickupCalendars/0t1gGcyw'
        },
        'recs:ups-three-day-select': {
          id: '0t1gGcyw',
          href: 'https://configuration.logistics.cimpress.io/v0/pickupCalendars/0t1gGcyw'
        },
        'recs:ups-next-day-air-saver': {
          id: '0t1gGcyw',
          href: 'https://configuration.logistics.cimpress.io/v0/pickupCalendars/0t1gGcyw'
        }
      }
    },
    deliveryCalendars: {
      calendars: [

      ]
    }
  }],
  currentLocation: {
    id: 'e2VBG7DF',
    name: 'Shipping Console Kiosk-Waltham Mail Room',
    address: {
      countryCode: 'US',
      locality: 'Waltham',
      postalCode: '02451',
      region: 'MA',
      street1: '275 Wyman Street',
      street2: ''
    },
    contact: {
      name: '',
      email: '',
      phone: ''
    },
    localeSettings: {
      timezone: 'America/New_York',
      dateFormat: 'MM/DD/YYYY',
      lengthUnits: 'in',
      weightUnits: 'lb',
      currencyCode: 'USD'
    },
    etag: 'db58914b-06ea-4fae-ad71-e9da7b3806a7',
    fulfillmentLocations: [
      {
        id: 'nqjnhpre',
        href: 'https://fulfillmentlocation.trdlnk.cimpress.io/v1/fulfillmentlocations/nqjnhpre'
      }
    ],
    carrierAccounts: [

    ],
    _links: {
      self: {
        href: 'https://configuration.logistics.cimpress.io/v0/locations/e2VBG7DF'
      }
    },
    workingDaysCalendar: {
      id: 'NV6ZOcfX',
      href: 'https://configuration.logistics.cimpress.io/v0/workingDaysCalendars/NV6ZOcfX'
    },
    transitCalendars: {
      mapping: {
        'recs:ups-ground': {
          id: 'KO_xlqWZS',
          href: 'https://configuration.logistics.cimpress.io/v0/workingDaysCalendars/KO_xlqWZS'
        },
        'recs:ups-second-day': {
          id: 'KO_xlqWZS',
          href: 'https://configuration.logistics.cimpress.io/v0/workingDaysCalendars/KO_xlqWZS'
        },
        'recs:ups-three-day-select': {
          id: 'KO_xlqWZS',
          href: 'https://configuration.logistics.cimpress.io/v0/workingDaysCalendars/KO_xlqWZS'
        },
        'recs:ups-next-day-air-saver': {
          id: 'KO_xlqWZS',
          href: 'https://configuration.logistics.cimpress.io/v0/workingDaysCalendars/KO_xlqWZS'
        }
      }
    },
    pickupCalendars: {
      mapping: {
        'recs:ups-ground': {
          id: '0t1gGcyw',
          href: 'https://configuration.logistics.cimpress.io/v0/pickupCalendars/0t1gGcyw'
        },
        'recs:ups-second-day': {
          id: '0t1gGcyw',
          href: 'https://configuration.logistics.cimpress.io/v0/pickupCalendars/0t1gGcyw'
        },
        'recs:ups-three-day-select': {
          id: '0t1gGcyw',
          href: 'https://configuration.logistics.cimpress.io/v0/pickupCalendars/0t1gGcyw'
        },
        'recs:ups-next-day-air-saver': {
          id: '0t1gGcyw',
          href: 'https://configuration.logistics.cimpress.io/v0/pickupCalendars/0t1gGcyw'
        }
      }
    },
    deliveryCalendars: {
      calendars: [

      ]
    }
  },
  loadingInProgress: false
}
