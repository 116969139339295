import store from '../../redux/index'

export function logIn () {
  const expire = localStorage.getItem('logout')
  if (expire && (new Date().getTime() > parseInt(expire))) logOut()
  let auth0 = store.getState().auth.auth0
  if (!auth0.isLoggedIn()) {
    store.dispatch({ type: 'LOG_OUT' })
    // This won't be hash after we migrate off angular
    return auth0.ensureAuthentication({nextUri: `/${window.location.hash}`}).then(function (success) {
      if (success) {
        let profile = getProfile()
        if (window.newrelic) {
          window.newrelic.setCustomAttribute('user', profile.sub)
        }
        store.dispatch({ type: 'LOG_IN', profile })
        return success
      } else {
        // Unreachable
        throw new Error('Could not log in')
      }
    })
  } else {
    return Promise.resolve(true)
  }
}

export function logOut () {
  let auth0 = store.getState().auth.auth0
  store.dispatch({ type: 'LOG_OUT' })
  localStorage.removeItem('logout')
  return auth0.logout('/', true)
}

export function isLoggedIn () {
  let auth0 = store.getState().auth.auth0
  return auth0.isLoggedIn()
}

export function getProfile () {
  let auth0 = store.getState().auth.auth0
  return auth0.getProfile()
}

export function getAccessToken () {
  let auth0 = store.getState().auth.auth0
  return auth0.getAccessToken()
}

export function getUserMetadata (applicationKey) {
  let userMetadata = store.getState().auth.userMetadata
  return userMetadata.getUserMetadata(applicationKey)
}

export function patchUserMetadata (newUserMetadata, applicationKey) {
  let userMetadata = store.getState().auth.userMetadata
  return userMetadata.patchUserMetadata(newUserMetadata, applicationKey)
}
