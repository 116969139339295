import React, { Component } from 'react'
import { withLocalize, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Button } from '@cimpress/react-components'
import autobind from 'react-autobind'

export class ShipBox extends Component {
  constructor (props) {
    super(props)
    autobind(this)
  }

  onClick () {
    event.preventDefault()
    this.props.createShipment()
  }

  getSplitShipmentButtonText() {
    let boxText

    if(this.props.numberOfShipments > 1) {
      boxText = this.props.translate('ship.MULTI_PACKAGE_NO_SHIP', {numPackages: this.props.numberOfShipments, packageType: this.props.translate('ship.packageType.BOX_PLU')})
    } else {
      boxText = this.props.translate('ship.ONE_PACKAGE_NO_SHIP', {packageType: this.props.translate('ship.packageType.BOX_SIN')})
    }

    return this.props.translate('ship.SHIP_LABEL') + ' ' + boxText
  }

  render () {
    return (
      <div className='ship-bar'>
        <div className='container' >
          <div className='flex column' style={{margin: 'auto', width: '64%'}}>
            <div className='flex justify-end'>
              <div style={{marginTop: '15px', marginBottom: '15px'}}>
                <Button
                  type='primary'
                  name='ship-button'
                  id='ship-button'
                  onClick={this.onClick}
                  className='btn'
                  disabled={this.props.disableShipButton}>
                  {this.getSplitShipmentButtonText()}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withLocalize(connect((state, ownProps) => {
  const {
    numberOfShipments,
    disableShipButton,
    createShipment
  } = ownProps

  return {
    numberOfShipments,
    disableShipButton,
    createShipment
  }
})(ShipBox))
