import Header from '@cimpress-technology/react-platform-header';
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withLocalize, getTranslate, getActiveLanguage } from 'react-localize-redux'
import { dispatchClearShipping} from '../../../redux/shipping/actions'
import * as authenticationService from '../../../lib/authentication/authenticationService'
import autobind from 'react-autobind'

// To insert into an HTML document, add <react-header></react-header>
export class ReactHeader extends React.Component {
  constructor (props) {
    super(props)
    autobind(this)
    this.state = { shouldShowModal: false }
  }

  onAppTitleClick(e) {
    if (!this.props.returnAddressExists) {
      e.preventDefault()
      return
    }

    dispatchClearShipping()
  }

  handleLanguageChange(code) {
    this.props.setActiveLanguage(code)
  }

  buildHeaderLinks() {
    const { translate } = this.props
    const activePath = this.props.location.pathname

    let appLinks = []

    if (this.props.returnAddressExists) {
      appLinks = appLinks.concat([
        {
          id: 'create-shipments',
          content:
            <Link
              id='create-shipments'
              to='/create-shipments'
              key='create-shipments'>
              {translate('headings.CREATE_SHIPMENTS')}
            </Link>,
          isActive: activePath === '/create-shipments'
        },
        {
          id: 'my-shipments',
          content:
            <Link
              id='my-shipments'
              to='/my-shipments'
              key='my-shipments'>
              {translate('headings.MY_SHIPMENTS')}
            </Link>,
          isActive: activePath === '/my-shipments'
        },
        {
          id: 'my-return-addresses',
          content:
            <Link
              id='my-return-addresses'
              to='/my-return-addresses'
              key='my-return-addresses'
            >
              {translate('headings.MY_RETURN_ADDRESSES')}
            </Link>,
          isActive: activePath === '/my-return-addresses'
        },
        {
          id: 'contact',
          content:
            <Link id='contact' to='/contact' key='contact'>{translate('headings.CONTACT')}</Link>,
          isActive: activePath === '/contact'
        }
      ])
    } else {
      appLinks = appLinks.concat([
        {
          id: 'create-return-address',
          content:
            <Link
              id='return-address'
              to='/create-return-address'
              key='create-return-address'
            >
              {translate('headings.CREATE_RETURN_ADDRESS')}
            </Link>,
          isActive: activePath === '/create-return-address'
        }
      ])
    }

    return appLinks
  }

  render () {
    const { translate } = this.props

    return (
      <div>
        <Header
          responsive
          appTitle={<Link id='ShippingConsoleTitle' onClick={this.onAppTitleClick} to={'/'}>{translate('menuBar.shippingConsole.TITLE')}</Link>}
          appLinks={this.buildHeaderLinks()}
          profile={this.props.profile}
          isLoggedIn={this.props.isAuthenticated}
          onLogInClicked={authenticationService.logIn}
          onLogOutClicked={authenticationService.logOut}
          showLeftNav={false}
          language={this.props.currentLanguage}
          accessToken={authenticationService.getAccessToken()}
          useBranding={true}
        />
      </div>
    )
  }
}

ReactHeader.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  profile: PropTypes.object
}

export default withLocalize(connect(
  (state) => {
    return {
      translate: getTranslate(state.localize),
      currentLanguage: getActiveLanguage(state.localize).code,
      isAuthenticated: state.auth.isAuthenticated,
      profile: state.auth.profile || undefined,
      hideBranding: state.settings ? state.settings.hideBranding : true,
      returnAddressExists: !!state.returnAddress.returnAddresses
    }
  }
)(ReactHeader))
