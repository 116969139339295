import React, { Component } from 'react'
import { getSupportEmailAddress } from '../../lib/email/addresses'
import { getTranslate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'

const pagerdutyEmail = 'pagelogisticssupport@cimpress.com'
const statusPage = 'https://status.cimpress.io'
const confluenceDocs = 'https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/457441351/Shipping+Console+Kiosk'

export class ContactView extends Component {
  render () {
    return (
      <div className='row'>
        <div className='col-md-6 col-md-offset-3'>
          <p>&nbsp;</p>
          <h2>
            <i className='fa fa-send'></i>&nbsp;
            {this.props.translate('contact.CONTACT_HEADER')}
          </h2>
          <p>{this.props.translate('contact.CONTACT_TEXT_1')}</p>
          <p>{this.props.translate('contact.CONTACT_TEXT_2')}</p>
          <p>{this.props.translate('contact.CONTACT_TEXT_3', { supportEmail: getSupportEmailAddress() })}</p>
          <br />
          <p>{this.props.translate('contact.CONTACT_TEXT_4', { pagerdutyEmail })}</p>
          <br />
          <p>
            {this.props.translate('contact.CONTACT_TEXT_6')}
            <a href={confluenceDocs}>{confluenceDocs}</a>
          </p> <br />
          <p>{this.props.translate('contact.CONTACT_TEXT_5', { statusPage })}</p>
        </div>
      </div>
    )
  }
}

export default withLocalize(connect(
  (state) => {
    return { translate: getTranslate(state.localize) }
  }
)(ContactView))
