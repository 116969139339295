import * as authenticationProxy from '../authentication/authenticationProxy'

export function getCarrierService(carrierServiceKey) {
  var request = {
    method: 'GET',
    url: `/api/v1/carrierServices/${carrierServiceKey}`
  }

  return authenticationProxy.makeAuthenticatedCapiRequest(request)
}
