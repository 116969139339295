import * as authenticationProxy from '../authentication/authenticationProxy'

export function sendEmail (templateId, data) {
  var request = {
    method: 'POST',
    url: `/v1/send/${templateId}`,
    data: data
  }

  return authenticationProxy.makeAuthenticatedPureMailRequest(request)
}