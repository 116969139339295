import Papa from 'papaparse'
import { v4 as uuid } from 'uuid'
import * as XLSX from 'xlsx'

import { dispatchShipments } from '../../redux/shipping/actions'

const DEFAULT_SHEET_NAME = 'uploadedShipments'

export function handleUpload(file) {
  let fileType = file.name.split('.').pop()
  const reader = new FileReader()
  if (fileType === 'xlsx') {
    reader.onload = handleXlsx
    reader.readAsBinaryString(file)
  } else if (fileType === 'csv') {
    reader.onload = handleCsv
    reader.readAsText(file)
  } 
  else {
    throw new Error('Unsupported file type.')
  }
}

 function handleXlsx(event) {
   const content = event.target.result
   let book = XLSX.read(content, { type: 'binary' })
   const sheetName =
     book.SheetNames.find((name) => {
       return name === DEFAULT_SHEET_NAME
     }) || book.SheetNames[0]
   const sheet = book.Sheets[sheetName]
   const data = XLSX.utils.sheet_to_csv(sheet, { header: 1 })
   let shipments = Papa.parse(data, { header: true, skipEmptyLines: true })
     .data
   let res = transformData(shipments)
   if (res.length === 0) {
     throw new Error(
       'File did not contain valid data. Please upload a xlsx/csv with proper shipments data. See the template for what data you need.'
     )
   } else {
     dispatchShipments(res)
   }
}

function handleCsv(event) {
  const content = event.target.result
  let shipments = Papa.parse(content, {
    header: true,
    skipEmptyLines: true
  }).data
  let data = transformData(shipments)
  if (data.length === 0) {
    throw new Error(
      'File did not contain valid data. Please upload a csv with proper shipments data. See the template for what data you need.'
    )
  } else {
    dispatchShipments(data)
  }
}

function transformData(shipments) {
  return shipments.map((shipment) => {
    return {
      shipmentId: uuid(),
      shippingAddress: {
        street: shipment['Street'],
        street2: shipment['Street2(optional)'],
        locality: shipment['Locality'],
        countryCode: shipment['CountryCode(two letters)'],
        name: shipment['Name'],
        company: shipment['Company(optional)'],
        phone: shipment['Phone(optional)'],
        region: shipment['Region(two letters)'],
        postalCode: shipment['PostalCode']
      },
      weight: Number(shipment['Weight(lb)'])
    }
  })
}
