export default {
  customShip: {
    enabled: true,
    resizeLabel: true,
    rotateLabel: true,
    displayPrices: true,
    hidePhoneNumber: true,
    defaultCountryCode: 'US',
    requestCustomDetails: true,
    showPayableByOptions: false,
    sendEmailNotification: true,
    displayNonContractPricing: true,
    requestDimensions: false
  },
  payableByRadio: true
}
