import { v4 as uuid } from 'uuid'
import store from '../index'
import * as errorFormattingService from '../../lib/common/errorFormattingService'
import React from 'react'

export function alert ({ level, slot = uuid(), message, dismissible = true }) {
  store.getState().alert.alerts
    .filter(alert => alert.level === level && alert.slot === slot).map(({ key }) => clear({ key }))
  const key = uuid()
  const formattedLevel = level.replace(/([A-Z])/g, '_$1').trim().toUpperCase() // camelCase -> CAMEL_CASE
  let displayMessage
  if (React.isValidElement(message)) {
    displayMessage = message
  } else if (message && message.stack && message.message) {
    displayMessage = message.message
  } else if (typeof message === 'string') {
    displayMessage = message
  } else {
    displayMessage = JSON.stringify(message)
  }
  if (slot === 'packingGuidance') {
    store.dispatch({ type: `ADD_${formattedLevel}_ALERT`, message, key, slot, dismissible: true })
  } else {
    store.dispatch({
      type: `ADD_${formattedLevel}_ALERT`,
      message: errorFormattingService.formatErrorMessage(displayMessage),
      key,
      slot,
      dismissible
    })
  }
  if (level === 'success') setTimeout(() => clear({ key }), 5000)
}

export function clear ({ level, slot, key, test } = {}) {
  if (key) store.dispatch({ type: 'REMOVE_ALERT', key })
  else {
    store.getState().alert.alerts
      .filter(alert => (!level || alert.level === level) && (!slot || alert.slot === slot) && (!test || test(alert)))
      .map(({ key }) => clear({ key }))
  }
}
